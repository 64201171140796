import MainBlock from "../block/main_blocks/block_news";
import NewsPage from "../block/news_page";
import { useEffect } from "react";


export default function News ({settem, setIsOpenPopUp}) {
    useEffect(() => {
        document.title = 'Новости';
      }, []);
    return (
        <main>
            <MainBlock />
            <NewsPage />
        </main>
    )
}