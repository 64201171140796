export default function ExpertiseBlock({settem, setIsOpenPopUp}) {
    return (
        <section className="expertise_block">
            <div className="container">
                <p className="subtitle expertise_subtitle">нам доверяют</p>
                <p className="expertise_title title">более 300 федеральных судей<br /> из различных регионов доверяют нам<br /> проведение экспертиз</p>
                <p className="text">Доверься и ты нам!</p>
                <button onClick={() => {setIsOpenPopUp(true); settem("Заказать экспертизу")}} className="white_button button">
                    Заказать экспертизу
                    <img src={process.env.PUBLIC_URL + "/img/expertise_block/button_arrow.png"} alt="стрелка на кнопке" />
                </button>
            </div>
        </section>
    )
}