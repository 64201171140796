import {Link} from "react-router-dom";

export default function ExpertiseBlock2({settem, setIsOpenPopUp}) {
    return (
        <>
            <section className="expertise_block_2">
                <div className="container">
                    <p className="subtitle expertise_subtitle">нам доверяют</p>
                    <p className="expertise_title title">более 300 федеральных судей<br /> из различных регионов доверяют нам<br /> проведение экспертиз</p>
                    <p className="text">Доверься и ты нам!</p>
                    <button onClick={() => {setIsOpenPopUp(true); settem("Заказать экспертизу")}} className="blue_button button">
                        Заказать экспертизу
                        <img src={process.env.PUBLIC_URL + "/img/expertise_block/button_arrow.png"} alt="стрелка на кнопке" />
                    </button>
                </div>
            </section>
            <section className="link_certificates">
                <div className="container">
                    <h2 className="title">сертификаты и документы</h2>
                    <Link to="/services/1/1#certificates">
                        <img src="/img/expertise_block/arrow.png" alt="стрелка" />
                    </Link>
                </div>
            </section>
        </>
    )
}