import {
    RouterProvider,
    createBrowserRouter,
    
} from "react-router-dom";
import Main from "../pages/main";
import React, { useState } from "react"
import PopUp from "../block/pop-up";
import Services from "../pages/services";
import Header from "../block/header";
import Footer from "../block/footer";
import NewsDetail from "../pages/news_detail";
import Contacts from "../pages/contacts";
import Equipment from "../pages/equipment";
import Career from "../pages/career";
import Command from "../pages/command";
import News from "../pages/news";
import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
const location = useLocation();
useEffect(() => {
    window.scrollTo(0, 0);

    
}, [location]);

return <>{props.children}</>
};

export default ScrollToTop;

function Layout(props) {
    let [tem, settem] = useState("");
    let [isOpenPopUp, setIsOpenPopUp] = useState(false);
    return (
        <>
            <Header isOpen={isOpenPopUp} tem={tem} setIsOpenPopUp={setIsOpenPopUp}/>
            {props.children(settem, setIsOpenPopUp, tem, isOpenPopUp)}
            <Footer/>
        </>
    )
}


export function AppRoute(props) {
    
    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <ScrollToTop>
                    <Layout>
                        {(settem, setIsOpenPopUp, tem, isOpenPopUp) => (
                            <>
                                <Main settem={settem} setIsOpenPopUp={setIsOpenPopUp}/>
                                <PopUp isOpen={isOpenPopUp} tem={tem} setIsOpenPopUp={setIsOpenPopUp}/>
                            </>
                        )}
                    </Layout>
                </ScrollToTop>
            ),
        },
        {
            path: "/services/:menuId/:subMenuId",
            element: (
                <ScrollToTop>
                    <Layout>
                        {(settem, setIsOpenPopUp, tem, isOpenPopUp) => (
                            <>
                                <Services
                                    settem={settem}
                                    setIsOpenPopUp={setIsOpenPopUp}
                                />
                                <PopUp
                                    isOpen={isOpenPopUp}
                                    tem={tem}
                                    setIsOpenPopUp={setIsOpenPopUp}
                                />
                            </>
                        )}
                    </Layout>
                </ScrollToTop>
            ),
        },
        {
            path: "/news/:newsId",
            element: (
                <ScrollToTop>
                    <Layout>
                        {(settem, setIsOpenPopUp, tem, isOpenPopUp) => (
                            <>
                                <NewsDetail
                                    settem={settem}
                                    setIsOpenPopUp={setIsOpenPopUp}
                                />
                                <PopUp
                                    isOpen={isOpenPopUp}
                                    tem={tem}
                                    setIsOpenPopUp={setIsOpenPopUp}
                                />
                            </>
                        )}
                    </Layout>
                </ScrollToTop>
            ),
        },
        {
            path: "/contacts",
            element: (
                <ScrollToTop>
                    <Layout>
                        {(settem, setIsOpenPopUp, tem, isOpenPopUp) => (
                            <>
                                <Contacts
                                    settem={settem}
                                    setIsOpenPopUp={setIsOpenPopUp}
                                />
                                <PopUp
                                    isOpen={isOpenPopUp}
                                    tem={tem}
                                    setIsOpenPopUp={setIsOpenPopUp}
                                />
                            </>
                        )}
                    </Layout>
                </ScrollToTop>
            ),
        },
        {
            path: "/equipment",
            element: (
                <ScrollToTop>
                    <Layout>
                        {(settem, setIsOpenPopUp, tem, isOpenPopUp) => (
                            <>
                                <Equipment
                                    settem={settem}
                                    setIsOpenPopUp={setIsOpenPopUp}
                                />
                                <PopUp
                                    isOpen={isOpenPopUp}
                                    tem={tem}
                                    setIsOpenPopUp={setIsOpenPopUp}
                                />
                            </>
                        )}
                    </Layout>
                </ScrollToTop>
            ),
        },
        {
            path: "/career",
            element: (
                <ScrollToTop>
                    <Layout>
                        {(settem, setIsOpenPopUp, tem, isOpenPopUp) => (
                            <>
                                <Career
                                    settem={settem}
                                    setIsOpenPopUp={setIsOpenPopUp}
                                />
                                <PopUp
                                    isOpen={isOpenPopUp}
                                    tem={tem}
                                    setIsOpenPopUp={setIsOpenPopUp}
                                />
                            </>
                        )}
                    </Layout>
                </ScrollToTop>
            ),
        },
        {
            path: "/command",
            element: (
                <ScrollToTop>
                    <Layout>
                        {(settem, setIsOpenPopUp, tem, isOpenPopUp) => (
                            <>
                                <Command
                                    settem={settem}
                                    setIsOpenPopUp={setIsOpenPopUp}
                                />
                                <PopUp
                                    isOpen={isOpenPopUp}
                                    tem={tem}
                                    setIsOpenPopUp={setIsOpenPopUp}
                                />
                            </>
                        )}
                    </Layout>
                </ScrollToTop>
            ),
        },
        {
            path: "/news",
            element: (
                <ScrollToTop>
                    <Layout>
                        {(settem, setIsOpenPopUp, tem, isOpenPopUp) => (
                            <>
                                <News
                                    settem={settem}
                                    setIsOpenPopUp={setIsOpenPopUp}
                                />
                            </>
                        )}
                    </Layout>
                </ScrollToTop>
            ),
        },
    ]);

    return <RouterProvider router={router}>{props.children}</RouterProvider>;
}
