import { dataServices } from "../data/servicesData";
import { useParams } from "react-router-dom";


export default function LaboratoryBlock({settem, setIsOpenPopUp}) {
    // Найдите индекс нужной услуги в массиве dataServices
    const param = useParams()

    let catIndex = dataServices.findIndex(service => service.menuId ===Number( param.menuId));

    const currentService = dataServices[catIndex];
    let childIndex = currentService.subMenuItems.findIndex(service => service.subMenuId === Number(param.subMenuId));

    const childService = currentService.subMenuItems[childIndex];

    if (childService.main_directions) {
        return (
            <section className="laboratory_block">
                <div className="container">
                    <div className="laboratory_inform_photo">
                        <div className="laboratory_inform">
                            <p dangerouslySetInnerHTML={{__html: childService.description_service}} className="description">
                            </p>
                            <details className="drop_down_block" name="laboratory_blocks">
                                <summary className="laboratory_inform_title main_directions">
                                    <div>
                                        <p dangerouslySetInnerHTML={{__html: childService.mainDirectionsHeading}} className="subtitle"></p>
                                    </div>
                                    <div>
                                        <img
                                            src={process.env.PUBLIC_URL + "/img/laboratory_block/arrow.png"}
                                            alt="Стрелка"
                                            className="arrow-toggler"
                                        />
                                    </div>
                                </summary>
                                <div dangerouslySetInnerHTML={{__html: childService.main_directions}} className="laboratory_inform_activ_block">
                                </div>
                            </details>
                            <details className="drop_down_block" name="laboratory_blocks">
                                <summary className="laboratory_inform_title">
                                    <div>
                                        <p className="subtitle">Задачи</p>
                                    </div>
                                    <div>
                                        <img
                                            src={process.env.PUBLIC_URL + "/img/laboratory_block/arrow.png"}
                                            alt="Стрелка"
                                            className="arrow-toggler"
                                        />
                                    </div>
                                </summary>
                                <div className="laboratory_inform_activ_block laboratory_inform_activ_block_background">
                                    <p dangerouslySetInnerHTML={{__html: childService.services_tasks}}  className="description laboratory_tasks_description">
                                    </p>
                                    <div className="laboratory_important_block">
                                        <div className="laboratory_important_block_title">
                                            <img src={process.env.PUBLIC_URL + "/img/laboratory_block/icons.png"} alt="Иконки"/>
                                            <p>ВАЖНО!</p>
                                        </div>
                                        <p className="laboratory_important_block_text">
                                            Постановка экспертных задач в рамках компетенции экспертов и формулировка конкретных вопросов осуществляется в ходе консультации
                                        </p>
                                    </div>
                                </div>
                            </details>
                            <details className="drop_down_block" name="laboratory_blocks">
                                <summary className="laboratory_inform_title">
                                <div>
                                    <p className="subtitle">На разрешение эксперта чаще всего ставятся вопросы:</p>
                                </div>
                                <div>
                                    <img
                                        src={process.env.PUBLIC_URL + "/img/laboratory_block/arrow.png"}
                                        alt="Стрелка"
                                        className="arrow-toggler"
                                    />
                                </div>
                                </summary>
                                <div dangerouslySetInnerHTML={{__html: childService.services_questions}}   className="laboratory_inform_activ_block laboratory_inform_activ_block_background">
                                </div>
                            </details>
                            <details className="drop_down_block" name="laboratory_blocks">
                                <summary className="laboratory_inform_title">
                                <div className="laboratory_important">
                                    <div>
                                        <img
                                            src={process.env.PUBLIC_URL + "/img/laboratory_block/mark.png"}
                                            alt="Восклицательный знак"
                                        />
                                    </div>
                                    <div className="">
                                        <p className="subtitle">важная информация!</p>
                                    </div>
                                </div>
                                <div>
                                    <img
                                        src={process.env.PUBLIC_URL + "/img/laboratory_block/arrow.png"}
                                        alt="Стрелка"
                                        className="arrow-toggler"
                                    />
                                </div>
                                </summary>
                                <div className="laboratory_inform_activ_block laboratory_inform_activ_block_important">
                                    <div className="laboratory_inform_activ_block_important_subtitle">
                                        <img src={process.env.PUBLIC_URL + "/img/laboratory_block/point.png"} alt="Точка"/>
                                        <p className="subtitle">ПРОВЕДЕНИЕ ЭКСПЕРТИЗЫ ПО УГОЛОВНОМУ ДЕЛУ</p>
                                    </div>
                                    <p className="description">В соответствии с Постановлением Пленума Верховного Суда Российской Федерации от 21 декабря 2010 года № 28 "О судебной экспертизе по уголовным делам" экспертизу по уголовному делу могут проводить государственные экспертные учреждения или некоммерческие организации, созданные в соответствии с законодательством и занимающиеся судебно-экспертной деятельностью на основе уставов.
                                    Коммерческие организации, лаборатории, индивидуальные предприниматели и образовательные учреждения не имеют права проводить экспертизу по уголовным делам, а также некоммерческие организации, уставная деятельность которых не предусматривает экспертизу. Экспертиза, проведенная такими организациями, может быть признана недопустимым доказательством, полученным с нарушением законодательства.<br/>
                                    Недопустимые доказательства не могут использоваться в судебном процессе, исследоваться или оглашаться на судебном заседании, и должны быть исключены из материалов уголовного дела.
                                    Поскольку АНО НЭКЦ "СУДЭКС" является некоммерческой организацией, осуществляющей судебные экспертизы как свою основную деятельность, она имеет право проводить экспертизы по уголовным делам.
                                    </p>
                                    <div className="laboratory_inform_activ_block_important_subtitle">
                                        <img src={process.env.PUBLIC_URL + "/img/laboratory_block/point.png"} alt="Точка"/>
                                        <p className="subtitle">ПРАВОВАЯ БАЗА ДЛЯ ПРОИЗВОДСТВА ЭКСПЕРТИЗЫ</p>
                                    </div>
                                    <p className="description">Федеральным законом № 73-ФЗ от 31 мая 2001 года установлены обязанности и права эксперта, проводящего автотехническую экспертизу (статьи 16 и 17). Статья 25 этого же Закона определяет процедуру составления экспертного заключения и его содержание.
                                    </p>
                                    <div className="laboratory_important_block">
                                        <div className="laboratory_important_block_title">
                                            <img src={process.env.PUBLIC_URL + "/img/laboratory_block/icons.png"} alt="Иконки"/>
                                            <p>ВНИМАНИЕ!</p>
                                        </div>
                                        <p className="laboratory_important_block_text">
                                            Список исследований, представленный на нашем сайте, не является полным. Для получения информации о доступных исследований, и постановки конкретных вопросов, рекомендуем обратиться за консультацией к специалисту до проведения исследования.
                                        </p>
                                    </div>
                                </div>
                            </details>
                        </div>
                        <div className="laboratory_photo">
                            <img src={process.env.PUBLIC_URL + currentService.photo} alt="картинка" />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    return (
        <section className="laboratory_block">
            <div className="container">
                <div className="laboratory_inform_photo">
                    <div className="laboratory_inform">
                        <p dangerouslySetInnerHTML={{__html: childService.description_service}} className="description">
                        </p>
                        <details className="drop_down_block" name="laboratory_blocks">
                            <summary className="laboratory_inform_title">
                                <div>
                                    <p className="subtitle">Задачи</p>
                                </div>
                                <div>
                                    <img
                                        src={process.env.PUBLIC_URL + "/img/laboratory_block/arrow.png"}
                                        alt="Стрелка"
                                        className="arrow-toggler"
                                    />
                                </div>
                            </summary>
                            <div className="laboratory_inform_activ_block laboratory_inform_activ_block_background">
                                <p dangerouslySetInnerHTML={{__html: childService.services_tasks}}  className="description laboratory_tasks_description">
                                </p>
                                <div className="laboratory_important_block">
                                    <div className="laboratory_important_block_title">
                                        <img src={process.env.PUBLIC_URL + "/img/laboratory_block/icons.png"} alt="Иконки"/>
                                        <p>ВАЖНО!</p>
                                    </div>
                                    <p className="laboratory_important_block_text">
                                        Постановка экспертных задач в рамках компетенции экспертов и формулировка конкретных вопросов осуществляется в ходе консультации
                                    </p>
                                </div>
                            </div>
                        </details>
                        <details className="drop_down_block" name="laboratory_blocks">
                            <summary className="laboratory_inform_title">
                            <div>
                                <p className="subtitle">На разрешение эксперта чаще всего ставятся вопросы:</p>
                            </div>
                            <div>
                                <img
                                    src={process.env.PUBLIC_URL + "/img/laboratory_block/arrow.png"}
                                    alt="Стрелка"
                                    className="arrow-toggler"
                                />
                            </div>
                            </summary>
                            <div dangerouslySetInnerHTML={{__html: childService.services_questions}}   className="laboratory_inform_activ_block laboratory_inform_activ_block_background">
                            </div>
                        </details>
                        <details className="drop_down_block" name="laboratory_blocks">
                            <summary className="laboratory_inform_title">
                            <div className="laboratory_important">
                                <div>
                                    <img
                                        src={process.env.PUBLIC_URL + "/img/laboratory_block/mark.png"}
                                        alt="Восклицательный знак"
                                    />
                                </div>
                                <div className="">
                                    <p className="subtitle">важная информация!</p>
                                </div>
                            </div>
                            <div>
                                <img
                                    src={process.env.PUBLIC_URL + "/img/laboratory_block/arrow.png"}
                                    alt="Стрелка"
                                    className="arrow-toggler"
                                />
                            </div>
                            </summary>
                            <div className="laboratory_inform_activ_block laboratory_inform_activ_block_important">
                                <div className="laboratory_inform_activ_block_important_subtitle">
                                    <img src={process.env.PUBLIC_URL + "/img/laboratory_block/point.png"} alt="Точка"/>
                                    <p className="subtitle">ПРОВЕДЕНИЕ ЭКСПЕРТИЗЫ ПО УГОЛОВНОМУ ДЕЛУ</p>
                                </div>
                                <p className="description">В соответствии с Постановлением Пленума Верховного Суда Российской Федерации от 21 декабря 2010 года № 28 "О судебной экспертизе по уголовным делам" экспертизу по уголовному делу могут проводить государственные экспертные учреждения или некоммерческие организации, созданные в соответствии с законодательством и занимающиеся судебно-экспертной деятельностью на основе уставов.
                                Коммерческие организации, лаборатории, индивидуальные предприниматели и образовательные учреждения не имеют права проводить экспертизу по уголовным делам, а также некоммерческие организации, уставная деятельность которых не предусматривает экспертизу. Экспертиза, проведенная такими организациями, может быть признана недопустимым доказательством, полученным с нарушением законодательства.<br/>
                                Недопустимые доказательства не могут использоваться в судебном процессе, исследоваться или оглашаться на судебном заседании, и должны быть исключены из материалов уголовного дела.
                                Поскольку АНО НЭКЦ "СУДЭКС" является некоммерческой организацией, осуществляющей судебные экспертизы как свою основную деятельность, она имеет право проводить экспертизы по уголовным делам.
                                </p>
                                <div className="laboratory_inform_activ_block_important_subtitle">
                                    <img src={process.env.PUBLIC_URL + "/img/laboratory_block/point.png"} alt="Точка"/>
                                    <p className="subtitle">ПРАВОВАЯ БАЗА ДЛЯ ПРОИЗВОДСТВА ЭКСПЕРТИЗЫ</p>
                                </div>
                                <p className="description">Федеральным законом № 73-ФЗ от 31 мая 2001 года установлены обязанности и права эксперта, проводящего автотехническую экспертизу (статьи 16 и 17). Статья 25 этого же Закона определяет процедуру составления экспертного заключения и его содержание.
                                </p>
                                <div className="laboratory_important_block">
                                    <div className="laboratory_important_block_title">
                                        <img src={process.env.PUBLIC_URL + "/img/laboratory_block/icons.png"} alt="Иконки"/>
                                        <p>ВНИМАНИЕ!</p>
                                    </div>
                                    <p className="laboratory_important_block_text">
                                        Список исследований, представленный на нашем сайте, не является полным. Для получения информации о доступных исследований, и постановки конкретных вопросов, рекомендуем обратиться за консультацией к специалисту до проведения исследования.
                                    </p>
                                </div>
                            </div>
                        </details>
                    </div>
                    <div className="laboratory_photo">
                        <img src={process.env.PUBLIC_URL + "/img/laboratory_block/picture.png"} alt="Картинка"/>
                    </div>
                </div>
            </div>
        </section>
    )
}
