import { useEffect, useState } from "react"
import {Link} from "react-router-dom";

export default function SliderExpertise(props) {
    let [mainSlide, setMainSlideHook] = useState([]);
    let [clientStartMousePosition, setClientMousePosition] = useState(0);

    useEffect(() => {
        for (let slide of props.slides) {
            if (slide.isMainSlide) {
                setMainSlideHook(slide);
            }
        }
    }, [props.slides])

    function setMainSlide(mainSlideId) {
        const newState = props.slides.map(obj => {
            if (obj.id === mainSlideId) {
                return {...obj, isMainSlide: true}
            } else {
                return {...obj, isMainSlide: false};
            }
        });
        props.setSlides(newState);
    }

    function showNextSlide() {
        let currentShowedSlides = [];
        for (let slide of props.slides) {
            if (slide.isShow) {
                currentShowedSlides.push(slide.id);
            }
        }
        let maxIdOfShowedSlide = Math.max(...currentShowedSlides);
        let minIdOfShowedSlide = Math.min(...currentShowedSlides);
        const newState = props.slides.map(obj => {
            if (maxIdOfShowedSlide < props.slides.length - 1) {
                if (obj.id === minIdOfShowedSlide) {
                    return {...obj, isShow: false};
                }
                if (obj.id === maxIdOfShowedSlide) {
                    return {...obj, isShow: true};
                }
                if (obj.id === maxIdOfShowedSlide + 1) {
                    return {...obj, isShow: true};
                }
            }
            return obj;
        });
        props.setSlides(newState);
    }

    function showPreviousSlide() {
        let currentShowedSlides = [];
        for (let slide of props.slides) {
            if (slide.isShow) {
                currentShowedSlides.push(slide.id);
            }
        }
        let maxIdOfShowedSlide = Math.max(...currentShowedSlides);
        let minIdOfShowedSlide = Math.min(...currentShowedSlides);
        const newState = props.slides.map(obj => {
            if (minIdOfShowedSlide > 0) {
                if (obj.id === maxIdOfShowedSlide) {
                    return {...obj, isShow: false};
                }
                if (obj.id === minIdOfShowedSlide) {
                    return {...obj, isShow: true};
                }
                if (obj.id === minIdOfShowedSlide - 1) {
                    return {...obj, isShow: true};
                }
            }
            return obj;
        });
        props.setSlides(newState);
    }
    
    function processTouchStartEvent(event) {
        setClientMousePosition(event.changedTouches[0].clientX);
    }

    function processTouchEndEvent(event) {
        let clientEndMousePosition = event.changedTouches[0].clientX;
        let mainSlideId;
        for (let slide of props.slides) {
            if (slide.isMainSlide) {
                mainSlideId = slide.id
            }
        }
        if (clientStartMousePosition - clientEndMousePosition < 0) {
            if (mainSlideId > 0)
                setMainSlide(mainSlideId - 1)
        } else {
            if (mainSlideId < props.slides.length - 1)
                setMainSlide(mainSlideId + 1)
        }
    }

    return (
        <div className="news_whole_slider">
            <div 
                className="main_slide" 
                onTouchStart={(e) => {processTouchStartEvent(e)}} 
                onTouchEnd={(e) => {processTouchEndEvent(e)}}
            >
                <img src={process.env.PUBLIC_URL + mainSlide.image} alt="картинка" />
                <p className="news_slide_date">{mainSlide.date}</p>
                <p className="subtitle">"{mainSlide.title}"</p>
                <div className="adaptiv_news_more news_more">
                    <Link to={`/news/${mainSlide.id}`}>Читать подробнее</Link>
                    <img src={process.env.PUBLIC_URL + "/img/news_block/more_arrow.png"} alt="стрелка" />
                </div>
                <button className="adaptiv_news_button blue_button button">
                    Все новости
                    <img src={process.env.PUBLIC_URL + "/img/fourth_block/button_arrow.png"} alt="стрелка на кнопке" />
                </button>
            </div>
            <div className="news_all_slides">
                <img onClick={() => {showPreviousSlide()}} className="slider_arrow slider_top" src={process.env.PUBLIC_URL + "/img/news_block/top.png"} alt="вверх" />
                {props.slides.map((item, idx) => (
                    <div
                        onClick={() => {setMainSlide(item.id)}}
                        key={idx} 
                        className={`news_slide ${item.isShow ? "show-slide" : ""}`}
                    >
                        <div className="news_slide_img">
                            <img src={process.env.PUBLIC_URL + item.image} alt="картинка" />
                        </div>
                        <div className="news_slide_inform">
                            <p className="subtitle">"{item.title}"</p>
                            <div className="news_more">
                                <Link to={`/news/${item.id}`}>Читать подробнее</Link>
                                <img src={process.env.PUBLIC_URL + "/img/news_block/more_arrow.png"} alt="стрелка" />
                            </div>
                            <p className="news_slide_date">{item.date}</p>
                        </div>
                    </div>
                ))}
                <img onClick={() => {showNextSlide()}} className="slider_arrow slider_bottom" src={process.env.PUBLIC_URL + "/img/news_block/bottom.png"} alt="вниз" />
            </div>
        </div>
    )
}
