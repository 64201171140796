import {useRef, useState} from "react";

export default function LicensesBlock({settem, setIsOpenPopUp}) {
    let [visibleSlides, setVisibleSlides] = useState(0);

    let [slides, setSlides] = useState([
        {
            "image": "/img/licenses_block/1.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СЕРТИФИКАТ "СУДЭКС"',
            "text": 'Сертификат соответствия судебного эксперта ...',
        },
        {
            "image": "/img/licenses_block/2.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СЕРТИФИКАТ "СУДЭКС"',
            "text": 'Сертификат соответствия судебного эксперта ...',
        },
        {
            "image": "/img/licenses_block/3.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СЕРТИФИКАТ "СУДЭКС"',
            "text": 'Сертификат соответствия судебного эксперта ...',
        },
        {
            "image": "/img/licenses_block/4.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СЕРТИФИКАТ "СУДЭКС"',
            "text": 'Сертификат соответствия судебного эксперта ...',
        },
        {
            "image": "/img/licenses_block/5.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СЕРТИФИКАТ "СУДЭКС"',
            "text": 'Сертификат соответствия судебного эксперта ...',
        },
        {
            "image": "/img/licenses_block/6.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СЕРТИФИКАТ "СУДЭКС"',
            "text": 'Сертификат соответствия судебного эксперта ...',
        },
        {
            "image": "/img/licenses_block/7.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СЕРТИФИКАТ "СУДЭКС"',
            "text": 'Сертификат соответствия судебного эксперта ...',
        },
        {
            "image": "/img/licenses_block/8.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СЕРТИФИКАТ "СУДЭКС"',
            "text": 'Сертификат соответствия судебного эксперта ...',
        },
        {
            "image": "/img/licenses_block/9.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СЕРТИФИКАТ "СУДЭКС"',
            "text": 'Сертификат соответствия судебного эксперта ...',
        },
        {
            "image": "/img/licenses_block/10.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СЕРТИФИКАТ "СУДЭКС"',
            "text": 'Сертификат соответствия судебного эксперта ...',
        },
        {
            "image": "/img/licenses_block/11.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СЕРТИФИКАТ "СУДЭКС"',
            "text": 'Сертификат соответствия судебного эксперта ...',
        },
        {
            "image": "/img/licenses_block/12.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СЕРТИФИКАТ "СУДЭКС"',
            "text": 'Сертификат соответствия судебного эксперта ...',
        },
        {
            "image": "/img/licenses_block/13.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СЕРТИФИКАТ "СУДЭКС"',
            "text": 'Сертификат соответствия судебного эксперта ...',
        },
        {
            "image": "/img/licenses_block/14.png",
            "altTextForImage": "лицензия",
            "subtitle": 'Сертификат Гильдии Кадастровых Инженеров',
            "text": 'Сертификат о членстве в ...',
        },
        {
            "image": "/img/licenses_block/15.png",
            "altTextForImage": "лицензия",
            "subtitle": 'ВЫПИСКА',
            "text": 'Из реестра экспертов- техников ...',
        },
        {
            "image": "/img/licenses_block/16.png",
            "altTextForImage": "лицензия",
            "subtitle": 'ВЫПИСКА',
            "text": 'Из реестра Членов союза лиц («СУДЭКС») ...',
        },
        {
            "image": "/img/licenses_block/17.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СЕРТИФИКАТ',
            "text": 'Сертификат соответствия судебного эксперта ...',
        },
        {
            "image": "/img/licenses_block/18.png",
            "altTextForImage": "лицензия",
            "subtitle": 'СВИДЕТЕЛЬСТВО',
            "text": 'Свидетельство является действительным Членом Союза лиц ...',
        }
    ]);

    let [isOpenModal, setIsOpenModal] = useState(false);
    let [currentImageInModal, setImageInModal] = useState({});
    let [clickCounter, setClickCounter] = useState(0);
    let [elementWidth, setElementWidth] = useState(0);
    const ref = useRef(null);
    const mainContainerRef = useRef(null);
    let [startDragPosition, setStartDragPosition] = useState(0);
    let [shiftTotalState, setShiftTotal] = useState(0);

    function openModal(indexImage) {
        let selectedImageObject = slides[indexImage];
        setImageInModal(selectedImageObject);
        setIsOpenModal(true);
    }

    function getCurrentOffset() {
        return Number(ref.current.style.transform.split("(")[1].split("px)")[0])
    }

    function getContainerWidth() {
        return mainContainerRef.current.offsetWidth;
    }

    function getCountVisibleSlidesInContainer(elementWidthLocal) {
        return Math.ceil(getContainerWidth() / elementWidthLocal);
    }

    function marginLeftLicenseContainer() {
        let newElementWidth = ref.current.scrollWidth / slides.length;
        setElementWidth(newElementWidth);
        setVisibleSlides(Math.floor(ref.current.offsetWidth / newElementWidth));
        
        let currentOffset = getCurrentOffset();
        let visibleSlides = getCountVisibleSlidesInContainer(newElementWidth);
        let shiftTotal;

        if (currentOffset <= -1) {
            shiftTotal = shiftTotalState - newElementWidth;
        } else {
            shiftTotal = newElementWidth * (slides.length - visibleSlides + 1);
        }

        setShiftTotal(shiftTotal);
    }
    
    function marginRightLicenseContainer() {
        let newElementWidth = ref.current.scrollWidth / slides.length;
        setElementWidth(newElementWidth);
        setVisibleSlides(Math.floor(ref.current.offsetWidth / newElementWidth));
        
        let currentOffset = getCurrentOffset();
        let visibleSlides = getCountVisibleSlidesInContainer(newElementWidth);
        let shiftTotal;
        let availableOffsetToShow = Math.floor((slides.length - visibleSlides + 1) * -newElementWidth)
        if (availableOffsetToShow >= Math.floor(currentOffset)) {
            shiftTotal = 0;
        } else {
            shiftTotal = (shiftTotalState ?? 0) + newElementWidth;
        }

        setShiftTotal(shiftTotal)
    }      

    function getStartDragPosition(event) {
        setStartDragPosition(event.changedTouches[0].clientX);
    }

    function moveContainerMobile(event) {
        let secondDragPosition = event.changedTouches[0].clientX;
        if (startDragPosition - secondDragPosition > 0) {
            marginRightLicenseContainer();
        } else {
            marginLeftLicenseContainer();
        }
    }
    

    return (
        <section className="licenses_block" id="certificates">
            <div className="container">
                <div className="licenses_block_left">
                    <h2 className="licenses_title title">Лицензии, документы и сертификаты</h2>
                    {/* <button onClick={() => {setIsOpenPopUp(true); settem("Заказать экспертизу")}} className="white_button button comp_vers">
                        Посмотреть все сертификаты
                        <img src={process.env.PUBLIC_URL + "/img/expertise_block/button_arrow.png"} alt="стрелка на кнопке" />
                    </button> */}
                    <div className="main_button_switches mobile_vers">
                        <button className="main_button main_button_left" onClick={() => {marginLeftLicenseContainer()}}>
                            <img src={process.env.PUBLIC_URL + "/img/main_block/left.png"} alt="влево" />
                        </button>
                        <button className="main_button main_button_right" onClick={() => {marginRightLicenseContainer()}}>
                            <img src={process.env.PUBLIC_URL + "/img/main_block/right.png"} alt="вправо" />
                        </button>
                    </div>
                </div>
                <button className="main_button main_button_left comp_vers" onClick={() => {marginLeftLicenseContainer()}}>
                    <img src={process.env.PUBLIC_URL + "/img/main_block/left.png"} alt="влево" />
                </button>
                <div className="licenses_block_container"
                    style={{maxWidth: "100%"}}
                    onTouchStart={(e) => {getStartDragPosition(e)}}
                    onTouchEnd={(e) => {moveContainerMobile(e)}}
                    ref={mainContainerRef}
                >

                    <div
                        className="licenses_block_right"
                        style={{transform: `translate(${String(-shiftTotalState)}px)`}}
                        ref={ref}
                    >
                        {slides.map((item, idx) => (
                            <div onClick={() => {
                                openModal(idx)
                            }} className="slide" key={idx}>
                                <div className="licenses_slide_card">
                                    <div>
                                        <img src={process.env.PUBLIC_URL + item.image} alt={item.altTextForImage}/>
                                    </div>
                                    <div className="licenses_slide_card_all_text">
                                        <p className="subtitle">{item.subtitle}</p>
                                        <p className="licenses_slide_card_text">{item.subtitle}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <button className="main_button main_button_right comp_vers" onClick={() => {
                    marginRightLicenseContainer()
                }}>
                    <img src={process.env.PUBLIC_URL + "/img/main_block/right.png"} alt="вправо"/>
                </button>
                {/* <button onClick={() => {
                    setIsOpenPopUp(true);
                    settem("Заказать экспертизу")
                }} className="white_button button mobile_vers">
                    Посмотреть все сертификаты
                    <img src={process.env.PUBLIC_URL + "/img/expertise_block/button_arrow.png"} alt="стрелка на кнопке"/>
                </button> */}
            </div>
            <div className={`license-pop_up ${isOpenModal ? "open" : ""}`} onClick={() => {
                setIsOpenModal(false)
            }}>
                <img src={currentImageInModal?.image} alt={currentImageInModal?.altTextForImage} />
            </div>
        </section>
    )
}
