import { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom";

export default function MainBlock({settem, setIsOpenPopUp}) {
    let navigate = useNavigate();
    let [slides, setSlides] = useState([
        {
            "id": 0,
            "title": "как помочь при протечке трубы в теплоснабжении ",
            "description": "Труба отопления начала протекать? Довольно распространенная проблема,  решение которой вызывает сложности у многих жильцов в квартирах и частных домах. При появлении протечки нужно действовать немедленно,  пока линию не прорвало. Следует понимать,  что не все неисправности можно устранить подручными средствами.",
            "image": "/img/main_block/fon.png",
            "showMoreUrl": "/news/5"
        },
        {
            "id": 1,
            "title": "что делать, если в квартире образовалась плесень",
            "description": "Управляющая компания не реагирует на проблему,  а самостоятельно устранить не удается",
            "image": "/img/main_block/fon2.png",
            "showMoreUrl": "/news/0"
        },
    ]);
    let [activeSlide, setActiveSlide] = useState({});
    let [activeSlideId, setActiveSlideId] = useState(0);

    useEffect(() => {
        setActiveSlide(slides[0]);
    }, [])

    function setNextSlide() {
        if (activeSlideId < slides.length - 1) {
            setActiveSlideId(activeSlideId + 1);
            setActiveSlide(slides[activeSlideId + 1]);
        } else {
            setActiveSlideId(0);
            setActiveSlide(slides[0]);
        }
    }

    function setPreviousSlide() {
        if (activeSlideId > 0) {
            setActiveSlideId(activeSlideId - 1);
            setActiveSlide(slides[activeSlideId - 1]);
        } else {
            setActiveSlideId(slides.length - 1);
            setActiveSlide(slides[slides.length - 1]);
        }
    }

    return (
        <section className="main_block primary_main_block" style={{backgroundImage: `url(${process.env.PUBLIC_URL + activeSlide.image})`}}>
            <div className="container">
                <h1 className="title_h1_main">СУД ЭКСПЕРТИЗА УЛЬЯНОВСК</h1>
                <p>
                    <span className="title main_title">{activeSlide.title}</span>
                </p>
                <p className="description main_description">{activeSlide.description}</p>
                <div className="main_all_button">
                    <button 
                        className="white_button button"
                        onClick={() => navigate(activeSlide.showMoreUrl)}
                    >
                        Подробнее
                        <img src={process.env.PUBLIC_URL + "/img/main_block/button_arrow.png"} alt="стрелка на кнопке" />
                    </button>
                    <div className="main_button_switches">
                        <button 
                            className="main_button main_button_left"
                            onClick={() => setPreviousSlide()}
                        >
                            <img src={process.env.PUBLIC_URL + "/img/main_block/left.png"} alt="влево" />
                        </button>
                        <button 
                            className="main_button main_button_right"
                            onClick={() => setNextSlide()}
                        >
                            <img src={process.env.PUBLIC_URL + "/img/main_block/right.png"} alt="вправо" />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}
