import MainBlock from "../block/main_blocks/block_services";
import LaboratoryBlock from "../block/laboratory_block";
import LicensesBlock from "../block/licenses_block";
import FeedbackBlock from "../block/feedback_block";
import NewsBlock from "../block/news_block";
import { useEffect } from "react";

export default function Services ({settem, setIsOpenPopUp}) {
    useEffect(() => {
        document.title = 'Услуги';
      }, []);
    return (
        <main>
            <MainBlock />
            <LaboratoryBlock />
            <LicensesBlock />
            <FeedbackBlock settem={settem} setIsOpenPopUp={setIsOpenPopUp}/>
            <NewsBlock />
        </main>
    )
}