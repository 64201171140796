import MainBlock from "../block/main_blocks/block_contacts";
import ContactBlock from "../block/contact_block";
import { useEffect } from "react";

export default function Contacts({settem, setIsOpenPopUp}) {
    useEffect(() => {
        document.title = 'Контакты';
      }, []);
    return(
        <main>
            <MainBlock />
            <ContactBlock />
        </main>
    )
}