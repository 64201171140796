import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import servicesData from "../../data/servicesData";
import "./services.css";

export default function MainBlockServices(props) {
    const {menuId, subMenuId} = useParams();
    let [menuTitle, setMenuTitle] = useState("");
    let [subMenuTitle, setSubMenuTitle] = useState("");
    useEffect(() => {
        let matchedResults = servicesData.filter((item) => item.menuId === Number(menuId));
        console.log(matchedResults);
        
        if (matchedResults.length === 1) {
            setMenuTitle(matchedResults[0].groupingMenu)
            let subMenuMatchedResults = matchedResults[0].subMenuItems.filter((item) => item.subMenuId === Number(subMenuId));
            if (subMenuMatchedResults.length === 1) {
                setSubMenuTitle(subMenuMatchedResults[0].subMenuTitle);
            }
        }
    });

    return (
        <section className="main_block main_block_service" style={{backgroundImage : `url(${servicesData.filter((item) => item.menuId === Number(menuId))[0].fon})`}}>
            <div  className="container">
                <div className="bread_crumbs">
                    <div>
                        <Link to="/">Главная</Link>
                    </div>
                    <div>{'>'}</div>
                    <div>Услуги</div>
                    <div>{'>'}</div>
                    <div>{menuTitle}</div>
                </div>
                <h1>
                    <span className="title main_title">{subMenuTitle}</span>
                </h1>
            </div>
        </section>
    )
}
