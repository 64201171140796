let news = [
    {
        id: 0,
        title: "что делать, если в квартире образовалась плесень",
        description:
            "Вот в таких ужасных условиях приходится проживать семьям с маленькими детьми в многоквартирном жилом доме. Плесень на стенах очень вредна для здоровья. Управляющая компания не реагирует.<br /><br />Наша организация провела обследование в данной квартире, выявила причину, разработала меры по избавлению от грибка и рассчитала стоимость восстановительного ремонта. А управляющую компанию ждут судебные тяжбы<br /><br />Если вы попали в похожую ситуацию то вы смело можете обращаться к нам и мы поможем!",
        date: "4 октября 2024",
        image: "/img/news_block/1.png",
        isShow: true,
        isMainSlide: true,
    },
    {
        id: 1,
        title: "вопрос: как расшифровать название нашей компании?",
        description:
            'ОТВЕТ: АНО НЭКЦ "СУДЭКС" переводится как Автономная Некоммерческая Организация - организация которая не имеет членства и создается с целью предоставления услуг в некоторых четко определенных сферах деятельности<br /><br />Национальный Экспертно Криминалистический Центр - занимаются как правило производством криминалистических и специальных экспертиз<br /><br />"СУДЭКС" - Союз лиц, осуществляющих деятельность в сфере судебной экспертизы и судебных экспертных исследований',
        date: "15 сентября 2024",
        image: "/img/news_block/2.png",
        isShow: true,
        isMainSlide: false,
    },
    {
        id: 2,
        title: "некачественное выполнение ремонтных работ в подъезде",
        description:
            "Если после капитального ремонта подъезда, у вас появились такие шедевры, обращайтесь к нам! Мы проведем экспертизу качества выполненных ремонтных работ, определим причины повреждений и рассчитаем стоимость восстановительных работ!",
        date: "9 августа 2024",
        image: "/img/news_block/3.png",
        isShow: false,
        isMainSlide: false,
    },
    {
        id: 3,
        title: "строительно-техническая экспертиза",
        description:
            "Одним из этапов производства строительно-технической экспертизы определения фактически выполненного объема и качества работ является - изучение проектно-сметной документации.",
        date: "27 июля 2024",
        image: "/img/news_block/4.png",
        isShow: false,
        isMainSlide: false,
    },
    {
        id: 4,
        title: "наши специалисты находят брак даже там, где их не просят",
        description:
            '"Профессиональный взгляд означает находить бракованные детали даже там,  где об этом не просят. Недавно наш специалист посетил завод с экскурсией и обнаружил одну такую деталь."<br /><br />(Мы не хотим никого обидеть своими записями,  мы просто делимся нашим опытом с вами).',
        date: "30 мая 2024",
        image: "/img/news_block/5.png",
        isShow: false,
        isMainSlide: false,
    },
    {
        id: 5,
        title: "как помочь при протечке трубы в теплоснабжении",
        description:
            "Труба отопления начала протекать? Довольно распространенная проблема,  решение которой вызывает сложности у многих жильцов в квартирах и частных домах. При появлении протечки нужно действовать немедленно,  пока линию не прорвало. Следует понимать,  что не все неисправности можно устранить подручными средствами.<br /><br />Как помочь? Экспертиза пролива тепловизором. В принципе, любые инструменты являются ценным активом только в том случае, когда они находятся в руках опытного специалиста.Если пользоваться прибором грамотно, тепловизор может стать одним из отличных инструментов, используемых при поиске протечек.",
        date: "12 мая 2024",
        image: "/img/news_block/6.png",
        isShow: false,
        isMainSlide: false,
    },
];

export default news;
