import { useState } from "react"

export default function QuestionsBlock(params) {
    let [isClicked, setIsClicked] = useState(false);
    let [isClickedList, setIsClickedList] = useState([]);
    let [isShowMoreQuestions, setIsShowMoreQuestions] = useState(false);

    let [data, setData] = useState([
        {
            "text": "У меня дома появилась плесень. Что делать?",
            "description": "Если ваш дом находится под управлением управляющей компании (УК), то в первую очередь необходимо вызвать представителей УК для составления акта. После этого нужно обратиться к эксперту, который поможет определить причины появления плесени.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Что делать, если сосед построил здание слишком близко к моему дому?",
            "description": "Вам следует обратиться к эксперту, чтобы проверить, соответствует ли постройка строительным нормам.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Можно ли провести экспертизу почерка, если есть только копии документов?",
            "description": "Да, можно. Однако качество копии будет иметь важное значение для результатов экспертизы.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Мне нужно сделать ремонт в комнате. Подскажите, как быть? Может ли мне помочь сметчик?",
            "description": "Сметчик не может точно определить, какое количество материалов вам потребуется.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Можно ли заключить с нами договор дистанционно?",
            "description": "Да, это возможно. Вы можете сделать это через любые электронные ресурсы.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },

        {
            "text": "Можем ли мы поехать в другой город?",
            "description": "Да, за дополнительную плату мы можем организовать поездку в любой город.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Какие документы необходимы для составления договора?",
            "description": "Для заключения договора вам понадобится только паспорт. В случае пролива или пожара также потребуется акт осмотра, составленный управляющей компанией.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Что делать если у нас был пролив, но уже все вытерто/отремонтировано",
            "description": "Если у вас был залив, но следы уже устранены, мы можем провести экспертизу на основе фотографий и видеоматериалов, подтверждающих качество выполненных работ.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Был пролив, но сейчас всё высохло. Как провести экспертизу?",
            "description": "Опытный эксперт обязательно найдёт всё необходимое для проведения экспертизы. Именно такие специалисты работают в нашей компании.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "А консультации у вас платные?",
            "description": "Консультации которые требуют (значительного) изучение и проведение каких либо исследований , проводятся платно как в офисе, так и онлайн",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },

        {
            "text": "Вы не могли бы оценить размер ущерба? Дело в том, что страховая компания оценила его не в полную сумму.",
            "description": "Мы можем произвести два расчёта: первый — по методике страховой компании, второй — на возмещение ущерба от лица, признанного виновным в его нанесении.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Можно ли провести экспертизу, чтобы определить виновника автомобильной аварии? ",
            "description": "Да, это возможно. Мы можем провести экспертизу, если есть административный материал и фото- или видеофиксация.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Можно ли провести тепловизионное обследование в летний период?",
            "description": "К сожалению, это невозможно. Для проведения обследования необходимо, чтобы разница температур внутри и снаружи помещения не превышала 15 градусов Цельсия.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Как проходит приёмка квартиры?",
            "description": "Приёмка осуществляется с помощью визуального и инструментального осмотра. По итогам осмотра составляется акт с печатью и подписью эксперта. Важно отметить, что не любой эксперт может проводить приёмку. Для этого необходимо состоять в специальном реестре экспертов. В нашей организации есть такой специалист.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Выездная консультация: что это?",
            "description": "Выездная консультация — это возможность провести визуальный и инструментальный осмотр на месте. Она позволяет определить, возможно ли провести экспертизу в данной ситуации.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },

        {
            "text": "Акт осмотра: что это?",
            "description": "Акт осмотра — это документ, который составляется во время осмотра и фиксирует выявленные нарушения или дефекты. В этом документе расписываются все участники осмотра. Он может использоваться в качестве доказательства.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Можно ли провести экспертизу почерка, если человек уже умер?",
            "description": "Да, это возможно, если есть документы с его подписью, которые были созданы примерно в тот период времени, который необходимо исследовать.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Можем ли мы выяснить причину пролива?",
            "description": "Да, можем. Однако если источник пролива находится в собственности соседей или в общедомовом имуществе, то для проведения осмотра и устранения проблемы нам потребуется беспрепятственный доступ.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Для чего нужна консультация?",
            "description": "Она позволяет понять, нужно ли проводить экспертизу в рамках вашей проблемы.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Зачем нам нужны фотографии и видео с места аварии или происшествия?",
            "description": "От того, насколько полными будут исходные данные, может зависеть окончательный вывод экспертизы.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },

        {
            "text": "Что такое разрушающий метод?",
            "description": "Это метод экспертного исследования, который в процессе применения приводит к повреждению объекта или к необратимым изменениям его состава, формы и внешнего вида.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Нужно ли  вскрывать телефон или другую бытовую технику во время проведения экспертизы?",
            "description": "Это может потребоваться, а может и нет. Но важно быть готовым к любым возможным последствиям.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Я хочу провести исследование техники, но она ещё на гарантии.",
            "description": "Чтобы определить возможные дефекты, необходимо использовать разрушающий метод. Однако при его применении товар автоматически теряет гарантию.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Что такое качество товара? ",
            "description": "Это соответствие товара стандартам, которые действуют на территории Российской Федерации.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Как определить, является ли товар подлинным?",
            "description": "Чтобы подтвердить или опровергнуть подлинность товара, нужно сравнить его с оригинальным (подлинным) образцом.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Как не купить подделку",
            "description": "Чтобы не столкнуться с подделкой, приобретайте товар только у официальных дистрибьюторов.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Можно ли провести товароведческую экспертизу по фотографиям? ",
            "description": "К сожалению, нет. Для определения причины возникновения дефектов необходимо использовать органолептический метод исследования, который предполагает визуальное изучение товара. Поэтому определить причину возникновения дефектов по фотографиям или видео невозможно.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
        {
            "text": "Делаете ли мы экспертизу ремонтно-отделочных работ",
            "description": "Да, мы проводим экспертизу. Для этого у нас есть необходимые инструменты.",
            "img": process.env.PUBLIC_URL + "/img/questions_block/plus.png",
            "alt": "плюс",
            "isClicked": false
        },
    ]);

    function toggleActive(indexElement) {
        let newState = data.map((obj, idx) => {
            if (idx === indexElement) {
                return {...obj, isClicked: !obj.isClicked};
            }
            return {...obj, isClicked: false};
        })
        setData(newState);
    }

    return (
        <section className="questions_block">
            <div className="container">
                <div className="title_and_button">
                    <div className="">
                        <h2 className="news_title title">Часто задаваемые вопросы</h2>
                    </div>
                    <button 
                        className="blue_button button"
                        onClick={() => {!isShowMoreQuestions ? setIsShowMoreQuestions(true) : setIsShowMoreQuestions(false)}}
                    >
                        {!isShowMoreQuestions ? "Смотреть все" : "Спрятать"}
                        <img src={process.env.PUBLIC_URL + "/img/fourth_block/button_arrow.png"} alt="стрелка на кнопке" />
                    </button>
                </div>
                <div className="all_questions">
                    {data.map((item, idx) => {
                        if (idx < 6) {
                            return (
                                <div key={idx} className={`question ${item.isClicked ? "active" : ""}`} onClick={() => {toggleActive(idx)}}>
                                    <div className="question_main">
                                        <p className="text">{item.text}</p>
                                        <div className="container_question_plus">
                                            <img className="question_plus" src={process.env.PUBLIC_URL + "/img/questions_block/plus.png"} alt="плюс" />
                                        </div>
                                    </div>
                                    <div className="question_activ_block">
                                        <hr />
                                        <p className="description question_description">
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                            )
                        } else {
                            if (isShowMoreQuestions) {
                                return (
                                    <div key={idx} className={`question ${item.isClicked ? "active" : ""}`} onClick={() => {toggleActive(idx)}}>
                                        <div className="question_main">
                                            <p className="text">{item.text}</p>
                                            <div className="container_question_plus">
                                                <img className="question_plus" src={process.env.PUBLIC_URL + "/img/questions_block/plus.png"} alt="плюс" />
                                            </div>
                                        </div>
                                        <div className="question_activ_block">
                                            <hr />
                                            <p className="description question_description">
                                                {item.description}
                                            </p>
                                        </div>
                                    </div>
                                )
                            }
                        }
                    })}
                </div>
                <button 
                    className="blue_button button button_adaptiv"
                    onClick={() => {!isShowMoreQuestions ? setIsShowMoreQuestions(true) : setIsShowMoreQuestions(false)}}
                >
                    {!isShowMoreQuestions ? "Смотреть все" : "Спрятать"}
                    <img src={process.env.PUBLIC_URL + "/img/fourth_block/button_arrow.png"} alt="стрелка на кнопке" />
                </button>
            </div>    
        </section>
    )
}
