export default function MiniBlock(params) {
    return (
        <section className="mini_block">
            <div className="mini_block_all_cards">
                <div className="mini_block_card_container">
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/1.png"} alt="Строительная экспертиза" />
                        <p className="mini_block_text">Строительная экспертиза</p>
                    </div>
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/2.png"} alt="Автотехническая экспертиза" />
                        <p className="mini_block_text">Автотехническая экспертиза</p>
                    </div>
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/3.png"} alt="Криминалистическая экспертиза" />
                        <p className="mini_block_text">Криминалистическая экспертиза</p>
                    </div>
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/4.png"} alt="Товароведческая экспертиза" />
                        <p className="mini_block_text">Товароведческая экспертиза</p>
                    </div>
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/5.png"} alt="Экономическая экспертиза" />
                        <p className="mini_block_text">Экономическая экспертиза</p>
                    </div>
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/6.png"} alt="Оценочная экспертиза" />
                        <p className="mini_block_text">Оценочная экспертиза</p>
                    </div>
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/7.png"} alt="Рецензия" />
                        <p className="mini_block_text">Рецензия</p>
                    </div>
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/8.png"} alt="Землеустроительная экспертиза" />
                        <p className="mini_block_text">Землеустроительная экспертиза</p>
                    </div>
                </div>
                <div className="mini_block_card_container">
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/1.png"} alt="Строительная экспертиза" />
                        <p className="mini_block_text">Строительная экспертиза</p>
                    </div>
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/2.png"} alt="Автотехническая экспертиза" />
                        <p className="mini_block_text">Автотехническая экспертиза</p>
                    </div>
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/3.png"} alt="Криминалистическая экспертиза" />
                        <p className="mini_block_text">Криминалистическая экспертиза</p>
                    </div>
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/4.png"} alt="Товароведческая экспертиза" />
                        <p className="mini_block_text">Товароведческая экспертиза</p>
                    </div>
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/5.png"} alt="Экономическая экспертиза" />
                        <p className="mini_block_text">Экономическая экспертиза</p>
                    </div>
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/6.png"} alt="Оценочная экспертиза" />
                        <p className="mini_block_text">Оценочная экспертиза</p>
                    </div>
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/7.png"} alt="Рецензия" />
                        <p className="mini_block_text">Рецензия</p>
                    </div>
                    <div className="mini_block_card">
                        <img src={process.env.PUBLIC_URL + "/img/mini_block/8.png"} alt="Землеустроительная экспертиза" />
                        <p className="mini_block_text">Землеустроительная экспертиза</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
