export default function MissionBlock(params) {
    return (
        <section className="mission_block" id="mission_block">
            <div className="container">
                <div className="mission_company">
                    <img src={process.env.PUBLIC_URL + "/img/command_block/mission_check.png"} alt="галочка" />
                    <h2 className="mission_title">Миссия компании</h2>
                    <p className="description">В служении людям, обществу, государству с целью защиты справедливости, закона и правды </p>
                </div>
            </div>
        </section>
    )
}