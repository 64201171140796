import { useState } from "react";
import PopUp from "./pop-up";

export default function ResumeBlock({settem, setIsOpenPopUp}) {
    let [subtitleModal, setSubtitleModal] = useState("Присоединяйся к нашей команде!");
    let [isOpenModal, setIsOpenModal] = useState(false);

    return (
        <section className="resume_block" id="resume_block">
            <div className="container">
                <h2 className="title">хотите стать частью нашей команды?</h2>
                <p className="description">Мы ждем именно Вас!</p>
                <button className="blue_button button" onClick={() => { setIsOpenModal(true) }}>
                    Присоединиться
                    <img src={process.env.PUBLIC_URL + "/img/fourth_block/button_arrow.png"} alt="стрелка на кнопке" />
                </button>
            </div>
            <PopUp isOpen={isOpenModal} tem={subtitleModal} setIsOpenPopUp={setIsOpenModal} />
        </section>
    )
}
