import {Link} from "react-router-dom";
import newsData from "../data/news";
import { useEffect, useState } from "react";

export default function NewsPage(params) {
    let [news, setNews] = useState([]);

    useEffect(() => {
        setNews(newsData);
    }, [])

    return (
        <section className="news_page">
            <div className="container">
                <h2 className="title">новости</h2>
                <div className="news_page_all">
                    {news.map((item, index) => {
                        return (
                            <div key={index} className="news_page_item">
                                <div className="news_slide_img">
                                    <img src={process.env.PUBLIC_URL + item.image} alt="картинка" />
                                </div>
                                <div className="news_slide_inform">
                                    <p className="subtitle">{item.title}</p>
                                    <div className="news_more">
                                        <Link to={`/news/${item.id}`}>Читать подробнее</Link>
                                        <img src={process.env.PUBLIC_URL + "/img/news_block/more_arrow.png"} alt="стрелка" />
                                    </div>
                                    <p className="news_slide_date">{item.date}</p>
                                </div>
                            </div>
                        )
                    })}
                    {/* <div className="news_page_item">
                        <div className="news_slide_img">
                            <img src="" alt="картинка" />
                        </div>
                        <div className="news_slide_inform">
                            <p className="subtitle">"Почему товароведческая экспертиза является важной частью контроля качества продукции."</p>
                            <div className="news_more">
                                <Link to={"/news/1"}>Читать подробнее</Link>
                                <img src={process.env.PUBLIC_URL + "/img/news_block/more_arrow.png"} alt="стрелка" />
                            </div>
                            <p className="news_slide_date">30 мая 2024</p>
                        </div>
                    </div>
                    <div className="news_page_item">
                        <div className="news_slide_img">
                            <img src="" alt="картинка" />
                        </div>
                        <div className="news_slide_inform">
                            <p className="subtitle">"Почему товароведческая экспертиза является важной частью контроля качества продукции."</p>
                            <div className="news_more">
                                <Link>Читать подробнее</Link>
                                <img src={process.env.PUBLIC_URL + "/img/news_block/more_arrow.png"} alt="стрелка" />
                            </div>
                            <p className="news_slide_date">30 мая 2024</p>
                        </div>
                    </div>
                    <div className="news_page_item">
                        <div className="news_slide_img">
                            <img src="" alt="картинка" />
                        </div>
                        <div className="news_slide_inform">
                            <p className="subtitle">"Почему товароведческая экспертиза является важной частью контроля качества продукции."</p>
                            <div className="news_more">
                                <Link>Читать подробнее</Link>
                                <img src={process.env.PUBLIC_URL + "/img/news_block/more_arrow.png"} alt="стрелка" />
                            </div>
                            <p className="news_slide_date">30 мая 2024</p>
                        </div>
                    </div>
                    <div className="news_page_item">
                        <div className="news_slide_img">
                            <img src="" alt="картинка" />
                        </div>
                        <div className="news_slide_inform">
                            <p className="subtitle">"Почему товароведческая экспертиза является важной частью контроля качества продукции."</p>
                            <div className="news_more">
                                <Link>Читать подробнее</Link>
                                <img src={process.env.PUBLIC_URL + "/img/news_block/more_arrow.png"} alt="стрелка" />
                            </div>
                            <p className="news_slide_date">30 мая 2024</p>
                        </div>
                    </div>
                    <div className="news_page_item">
                        <div className="news_slide_img">
                            <img src="" alt="картинка" />
                        </div>
                        <div className="news_slide_inform">
                            <p className="subtitle">"Почему товароведческая экспертиза является важной частью контроля качества продукции."</p>
                            <div className="news_more">
                                <Link>Читать подробнее</Link>
                                <img src={process.env.PUBLIC_URL + "/img/news_block/more_arrow.png"} alt="стрелка" />
                            </div>
                            <p className="news_slide_date">30 мая 2024</p>
                        </div>
                    </div>
                    <div className="news_page_item">
                        <div className="news_slide_img">
                            <img src="" alt="картинка" />
                        </div>
                        <div className="news_slide_inform">
                            <p className="subtitle">"Почему товароведческая экспертиза является важной частью контроля качества продукции."</p>
                            <div className="news_more">
                                <Link>Читать подробнее</Link>
                                <img src={process.env.PUBLIC_URL + "/img/news_block/more_arrow.png"} alt="стрелка" />
                            </div>
                            <p className="news_slide_date">30 мая 2024</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}
