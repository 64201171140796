import allNews from "../data/news";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

export default function AllNewsBlock(props) {
    const { newsId } = useParams();
    const navigate = useNavigate();

    function goToNextNews() {
        let _newsId = Number(newsId);
        // Если вы на последней новости, перейти к первой
        if (_newsId === allNews.length - 1) {
            navigate(`/news/0`, { replace: true });
        } else {
            navigate(`/news/${_newsId + 1}`, { replace: true });
        }
    }

    function goToPrevNews() {
        let _newsId = Number(newsId);
        // Если вы на первой новости, перейти к последней
        if (_newsId === 0) {
            navigate(`/news/${allNews.length - 1}`, { replace: true });
        } else {
            navigate(`/news/${_newsId - 1}`, { replace: true });
        }
    }

    return (
        <section className="all_news_block">
            <div className="container">
                <div className="all_news_block-slide">
                    <div className="all_news_block_picture">
                        <img src={process.env.PUBLIC_URL + props.news.image} alt="картинка" />
                    </div>
                    <div className="all_news_block_inform">
                        <div className="all_news_block_date">
                            <img src={process.env.PUBLIC_URL + "/img/all_news_block/icon.png"} alt="иконка" />
                            <p className="news_slide_date">{props.news.date}</p>
                        </div>
                        <p className="description" dangerouslySetInnerHTML={{ __html: props.news.description }}></p>
                        <Link to="https://vk.com/rusnekc">
                            <button className="blue_button button">
                                Подписаться на новости
                                <img src={process.env.PUBLIC_URL + "/img/fourth_block/button_arrow.png"} alt="стрелка на кнопке" />
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="all_news_block_navigation">
                    <button
                        className="prev"
                        onClick={() => {
                            goToPrevNews();
                        }}>
                        <img src={process.env.PUBLIC_URL + "/img/all_news_block/prev.png"} alt="предыдущий" />
                        Назад
                    </button>
                    <button
                        className="next"
                        onClick={() => {
                            goToNextNews();
                        }}>
                        Следующая новость
                        <img src={process.env.PUBLIC_URL + "/img/all_news_block/next.png"} alt="следующий" />
                    </button>
                </div>
            </div>
        </section>
    );
}
