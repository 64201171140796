import SliderExpertise from "./slider_expertise";
import news from "../data/news";
import { useState } from "react";
import {Link} from "react-router-dom";

export default function NewsBlock(params) {
    let [slides, setSlides] = useState(news);
    
    function setNextSlide() {
        let currentMainSlideId = getCurrentMainSlide();
        let newState = slides.map((item, idx) => {
            if (currentMainSlideId < slides.length - 1) {
                if (item.isMainSlide) {
                    return {...item, isMainSlide: false}
                }
                if (item.id === currentMainSlideId + 1) {
                    return {...item, isMainSlide: true}
                }
            }
            return item;
        })
        setSlides(newState);
    }

    function setPreviousSlide() {
        let currentMainSlideId = getCurrentMainSlide();
        let newState = slides.map((item, idx) => {
            if (currentMainSlideId > 0) {
                if (item.isMainSlide) {
                    return {...item, isMainSlide: false}
                }
                if (item.id === currentMainSlideId - 1) {
                    return {...item, isMainSlide: true}
                }
            }
            return item;
        })
        setSlides(newState);
    }

    function getCurrentMainSlide() {
        for (let slide of slides) {
            if (slide.isMainSlide) {
                return slide.id;
            }
        }
    }

    return (
        <section className="news_block">
            <div className="container">
                <div className="title_and_button">
                    <div className="">
                        <h2 className="news_title title">делимся экспертизой</h2>
                    </div>
                    <div className="button_and_vk">
                        <Link to="/news">
                            <button className="blue_button button">
                                Все новости
                                <img src={process.env.PUBLIC_URL + "/img/fourth_block/button_arrow.png"} alt="стрелка на кнопке" />
                            </button>
                        </Link>
                        <a href="https://vk.com/rusnekc"><img className="button_vk" src={process.env.PUBLIC_URL + "/img/news_block/vk.png"} alt="вк" /></a>
                    </div>
                    <div className="main_button_switches">
                        <button onClick={() => {setPreviousSlide()}} className="main_button main_button_left">
                            <img src={process.env.PUBLIC_URL + "/img/main_block/left.png"} alt="влево" />
                        </button>
                        <button onClick={() => {setNextSlide()}} className="main_button main_button_right">
                            <img src={process.env.PUBLIC_URL + "/img/main_block/right.png"} alt="вправо" />
                        </button>
                    </div>
                </div>
                <SliderExpertise slides={slides} setSlides={setSlides} />
                {/* <div className="news_whole_slider">
                    <div className="main_slide">
                        <img src={process.env.PUBLIC_URL + "img/news_block/1.png"} alt="картинка" />
                        <p className="news_slide_date">30 мая 2024</p>
                        <p className="subtitle">"Почему товароведческая экспертиза является важной частью контроля качества продукции."</p>
                        <div className="adaptiv_news_more news_more">
                            <a href="#">Читать подробнее</a>
                            <img src={process.env.PUBLIC_URL + "img/news_block/more_arrow.png"} alt="стрелка" />
                        </div>
                        <button className="adaptiv_news_button blue_button button">
                            Все новости
                            <img src={process.env.PUBLIC_URL + "img/fourth_block/button_arrow.png"} alt="стрелка на кнопке" />
                        </button>
                    </div>
                    <div className="news_all_slides">
                        <img className="slider_arrow slider_top" src={process.env.PUBLIC_URL + "img/news_block/top.png"} alt="вверх" />
                        <div className="news_slide">
                            <div className="news_slide_img">
                                <img src={process.env.PUBLIC_URL + "img/news_block/picture.png"} alt="картинка" />
                            </div>
                            <div className="news_slide_inform">
                                <p className="subtitle">"Почему товароведческая экспертиза является важной частью контроля качества продукции."</p>
                                <div className="news_more">
                                    <a href="#">Читать подробнее</a>
                                    <img src={process.env.PUBLIC_URL + "img/news_block/more_arrow.png"} alt="стрелка" />
                                </div>
                                <p className="news_slide_date">30 мая 2024</p>
                            </div>
                        </div>
                        <div className="news_slide">
                            <div className="news_slide_img">
                                <img src={process.env.PUBLIC_URL + "img/news_block/picture.png"} alt="картинка" />
                            </div>
                            <div className="news_slide_inform">
                                <p className="subtitle">"Почему товароведческая экспертиза является важной частью контроля качества продукции."</p>
                                <div className="news_more">
                                    <a href="#">Читать подробнее</a>
                                    <img src={process.env.PUBLIC_URL + "img/news_block/more_arrow.png"} alt="стрелка" />
                                </div>
                                <p className="news_slide_date">30 мая 2024</p>
                            </div>
                        </div>
                        <img className="slider_arrow slider_bottom" src={process.env.PUBLIC_URL + "img/news_block/bottom.png"} alt="вниз" />
                    </div>
                </div> */}
            </div>    
        </section>
    )
}
