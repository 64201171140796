import { useState } from "react";
import {Link} from "react-router-dom";

export default function SearchBlock(params) {
    let [isOpenSearch, setIsOpenSearch] = useState(false);

    function toggleModal() {
        setIsOpenSearch(!isOpenSearch);
    }

    return (
        <section className="search_block">
            <div className="container">
                <div className="search_block_inform">
                    <h2 className="search_title title">Наши эксперты найдут решение любой юридической проблемы</h2>
                    <p className="search_description description">Быстрый поиск по услугам:</p>
                    <form action="">
                        <div className="search-container">
                            {/* <input type="text" name="text" className="search" placeholder="Пожарная экспертиза" /> */}
                            <div className="search" onClick={() => {toggleModal()}}>Выбрать направление...</div>
                            {isOpenSearch && (
                                <div style={{width: "100%", background: "white", padding: "8px 10px", marginTop: "0"}} className="search_menu">
                                    <details className="burger_details" name="search_menu_body">
                                        <summary>
                                            <div>Лаборатория строительно-технических и землеустроительных экспертиз</div>
                                            <img src={process.env.PUBLIC_URL + "/img/header/arrow_right_burger.png"} alt=""/>
                                        </summary>
                                        <div>
                                            <ul>
                                                <Link to="/services/1/1">
                                                    <p>&bull; Строительно-техническая экспертиза</p>
                                                </Link>
                                                <Link to="/services/1/2">
                                                    <p>&bull; Пожарно-техническая экспертиза</p>
                                                </Link>
                                                <Link to="/services/1/3">
                                                    <p>&bull; Экологическая экспертиза</p>
                                                </Link>
                                                <Link to="/services/1/4">
                                                    <p>&bull; Землеустроительная экспертиза</p>
                                                </Link>
                                            </ul>
                                        </div>
                                    </details>
                                    <details className="burger_details" name="search_menu_body">
                                        <summary>
                                            <div>Лаборатория товароведческих и финансово-экономических экспертиз</div>
                                            <img src={process.env.PUBLIC_URL + "/img/header/arrow_right_burger.png"} alt=""/>
                                        </summary>
                                        <div>
                                            <ul>
                                                <Link to="/services/3/1">
                                                    <p>&bull; Товароведческая экспертиза</p>
                                                </Link>
                                                <Link to="/services/3/2">
                                                    <p>&bull; Финансово-экономическая и бухгалтерская экспертиза</p>
                                                </Link>
                                                <Link to="/services/3/3">
                                                    <p>&bull; Оценочная экспертиза</p>
                                                </Link>
                                            </ul>
                                        </div>
                                    </details>
                                    <details className="burger_details" name="search_menu_body">
                                        <summary>
                                            <div>Лаборатория автотехнических и комплексных технических экспертиз</div>
                                            <img src={process.env.PUBLIC_URL + "/img/header/arrow_right_burger.png"} alt=""/>
                                        </summary>
                                        <div>
                                            <ul>
                                                <Link to="/services/2/1">
                                                    <p>&bull; Автотехническая экспертиза</p>
                                                </Link>
                                                <Link to="/services/2/2">
                                                    <p>&bull; Автотовароведческая экспертиза</p>
                                                </Link>
                                                <Link to="/services/2/3">
                                                    <p>&bull; Автооценочная экспертиза</p>
                                                </Link>
                                                <Link to="/services/2/4">
                                                    <p>&bull; Экспертиза обстоятельств ДТП</p>
                                                </Link>
                                            </ul>
                                        </div>
                                    </details>
                                    <details className="burger_details" name="search_menu_body">
                                        <summary>
                                            <div>Лаборатория криминалистических и комплексных экспертиз</div>
                                            <img src={process.env.PUBLIC_URL + "/img/header/arrow_right_burger.png"} alt=""/>
                                        </summary>
                                        <div>
                                            <ul>
                                                <Link to="/services/4/1">
                                                    <p>&bull; Криминалистическая экспертиза</p>
                                                </Link>
                                                <Link to="/services/4/2">
                                                    <p>&bull; Почерковедческая экспертиза</p>
                                                </Link>
                                                <Link to="/services/4/3">
                                                    <p>&bull; Экспертиза давности документов</p>
                                                </Link>
                                            </ul>
                                        </div>
                                    </details>
                                </div>
                            )}
                        </div>
                    </form>
                    <p className="search_description2 description">Не нашли ответ на подходящий вопрос</p>
                </div>
                <div className="search_block_picture">
                    <img src={process.env.PUBLIC_URL + "/img/search_block/picture.png"} alt="картинка" />
                </div>
            </div>
        </section>
    )
}
