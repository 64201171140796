import { useSearchParams } from "react-router-dom";
import ModalWithContent from "./modal_with_content";
import { useState } from "react";

export default function PresentationBlock(params) {
    let [isOpenModal, setIsOpenModal] = useState(false);

    function downloadPDF() {
        const pdfUrl = "presentation.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "presentation.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <section className="presentation_block" id="presentation_block">
            <div className="container">
                <h2 className="title">Презентация компании</h2>
                <p className="description">
                    Нашей компанией ежегодно реализуется более 2500 проектов, исследований, экспертиз, оказывая услуги в 6 регионах России
                </p>
                <div className="presentation_block_switches">
                    <button className="main_button presentation_button_left" onClick={() => {downloadPDF()}}>
                        <p className="presentation_button_left_text">Скачать презентацию</p>
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/download.png"} alt="Скачать презентацию" />
                    </button>
                    <button className="main_button presentation_button_right" onClick={() => {setIsOpenModal(true)}}>
                        <p className="presentation_button_left_text">Смотреть презентацию</p>
                        <img src={process.env.PUBLIC_URL + "/img/main_block/right.png"} alt="Смотреть презентацию"/>
                    </button>
                </div>
            </div>
            <ModalWithContent isOpen={isOpenModal} setIsOpenModal={setIsOpenModal}>
                <div className="presentation_all_slides">
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/1.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/2.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/3.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/4.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/5.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/6.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/7.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/8.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/9.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/10.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/11.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/12.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/13.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/14.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/15.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/16.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/17.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/18.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/19.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/20.png"} alt="слайд" />
                    </div>
                    <div className="presentation_slide">
                        <img src={process.env.PUBLIC_URL + "/img/presentation_block/21.png"} alt="слайд" />
                    </div>
                </div>
            </ModalWithContent>
        </section>
    )
}
