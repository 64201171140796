export default function FeedbackBlock({settem, setIsOpenPopUp}) {
    return (
        <section className="feedback_block">
            <div className="container">
                <p className="title">не нашли ответа <br/>
                или же У ВАС ОСТАЛИСЬ ВОПРОСЫ?</p>
                <p className="description">Свяжитесь с нами, и мы Вам поможем!</p>
                <button className="blue_button button" onClick={() => {setIsOpenPopUp(true); settem("Оставьте заявку, мы перезвоним и обсудим детали")}}>
                    Получить консультацию
                    <img src={process.env.PUBLIC_URL + "/img/fourth_block/button_arrow.png"} alt="стрелка на кнопке" />
                </button>
            </div>
        </section>
    )
}