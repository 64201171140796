import MainBlock from "../block/main_blocks/block_career";
import CareerBlock from "../block/career_block";
import ResumeBlock from "../block/resume_block";
import GalleryBlock from "../block/gallery_block";
import { useEffect } from "react";

export default function Career({settem, setIsOpenPopUp}) {
    useEffect(() => {
        document.title = 'Карьера';
      }, []);
    return(
        <main>
            <MainBlock />
            <CareerBlock />
            <ResumeBlock settem={settem} setIsOpenPopUp={setIsOpenPopUp}/>
            <GalleryBlock />
        </main>
    )
}