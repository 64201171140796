import AllNewsBlock from "../block/all_news_block";
import ContactBlock from "../block/contact_block";
import EquipmentBlock from "../block/equipment_block";
import ExpertiseBlock from "../block/expertise_block";
import FeedbackBlock from "../block/feedback_block";
import FourthBlock from "../block/fourth_block";
import LaboratoryBlock from "../block/laboratory_block";
import LicensesBlock from "../block/licenses_block";
import MainBlock from "../block/main_block";
import MiniBlock from "../block/mini_block";
import NewsBlock from "../block/news_block";
import QuestionsBlock from "../block/questions_block";
import SearchBlock from "../block/search_block";
import { useEffect } from "react";


export default function Main({settem, setIsOpenPopUp}) {
    useEffect(() => {
        document.title = 'СУД ЭКСПЕРТИЗА УЛЬЯНОВСК';
      }, []);
    return(
        <main>
            <MainBlock />
            <MiniBlock />
            <SearchBlock />
            <FourthBlock settem={settem} setIsOpenPopUp={setIsOpenPopUp}/>
            <ExpertiseBlock settem={settem} setIsOpenPopUp={setIsOpenPopUp}/>
            <NewsBlock />
            <QuestionsBlock />
        </main>
    )
}