import { useState, useEffect } from "react"

export default function ModalWithContent(props) {
    let [userYScroll, setUserYScroll] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [props.isOpen]);

    const handleScroll = () => {
        const position = window.scrollY;
        setUserYScroll(position);
    };
    
    return (
        <div className={`modal_with_content ${props.isOpen ? "modal-open" : ""}`} style={{top: `${userYScroll}px`}} onClick={() => {props.setIsOpenModal(false)}}>
            <div className="modal-with-content__container">
                {props.children}
            </div>
        </div>
    )
}
