import ModalWithContent from "./modal_with_content";
import { useEffect, useState } from "react";

export default function PresentationBlock(params) {
    let [isOpenModal, setIsOpenModal] = useState(false);
    let [data, setData] = useState([
        // {
        //     "id": 0,
        //     "image": "/img/management_block/1.png",
        //     "fullName": "Андриянов<br /> Валерий Михайлович",
        //     "descriptionCard": "Председатель правления<br /> Партнер",
        //     "descriptionModal": `<b>Образование: </b><br/>
        //     1995-1999 гг. «Ульяновский автомеханический техникум»;<br/>
        //     1999-2004 гг. «Ульяновский государственный технический университет»;<br/>
        //     2010-2011 гг. «Ульяновский государственный университет» по программе «Оценка стоимости предприятия (бизнеса)»;<br/>
        //     Ноябрь-октябрь 2017 г. «Ульяновский государственный технический университет» по программе: MBA - «Мастер делового администрирования».<br/><br/>
        //     <b>Специальность по образованию:</b><br/>
        //     «Ульяновский автомеханический техникум» по специальности: «Автомобилестроение»;<br/>
        //     «Ульяновский государственный технический университет» по специальности: «Автомобиле- и тракторостроение».<br/><br/>
        //     <b>Послевузовское образование:</b><br/>
        //     2010-2011 гг. Обучение по программе (Некоммерческое партнерство «Палата судебных экспертов» (НП «СУДЭКС» - РФЦСЭ при Министерстве юстиции РФ);<br/>
        //     Март-апрель 2014 г. Некоммерческое партнерство «Палата судебных экспертов» по программе «Профессиональная переподготовка экспертов-техников»;<br/><br/>
        //     <b>Какими языками владеет: </b>русский, английский<br/><br/>
        //     <b>Повышение квалификации:</b><br/>
        //     Сертифицирован в системе добровольной сертификации негосударственных судебных экспертов (РОСС RU.И597.04 НЯ00 при (НП «СУДЭКС» - РФЦСЭ при Министерстве юстиции РФ)):<br />
        //     сертификат  соответствия по судебно-экспертной специальности:               <br />
        //     -	13.1 «Исследование обстоятельств дорожно-транспортного происшествия»;<br />
        //     сертификат  соответствия по судебно-экспертной специальности:               <br />
        //     -	13.2 «Исследование технического состояния транспортных средств»;<br />
        //     сертификат  соответствия по судебно-экспертной специальности:               <br />
        //     -	13.3 «Исследование следов на транспортных средствах и месте ДТП (транспортно-трасологическая диагностика)»;<br />
        //     сертификат соответствия по судебно-экспертной специальности:                                    <br />
        //     -	13.4 «Исследование транспортных средств в целях определения стоимости восстановительного ремонта и остаточной стоимости»;<br />
        //     сертификат соответствия по судебно-экспертной специальности:                                     <br />
        //     -	13.5 «Исследование технического состояния дороги, дорожных условий на месте дорожно-транспортного происшествия»;<br />
        //     сертификат соответствия по судебно-экспертной специальности:                                     <br />
        //     -	13.6 «Исследование транспортных средств по выявлению дефектов, качеству сборки, ремонта и рекламациям»;<br />
        //     сертификат соответствия по судебно-экспертной специальности:		                  <br />
        //     -	32.1 «Экспертная деятельность медиаторов (посредников-участников досудебной процедуры урегулирования споров)»;<br />
        //     -	Член саморегулируемой организации оценщиков «Российское Общество Оценщиков»;<br />
        //     -	Сертификаты о прохождении семинара и тренера по работе с программными продуктами  «Audatex», (ООО «Аудатэкс»);<br />
        //     -	Сертификат о прохождении семинара по теме «PC-Crash 10.0» (Реконструкция и моделирование ДТП);<br />
        //     -	Аттестованный пользователь измерительной системы «SIVER-DATA», ЗАО «Сивер», (установление соответствия геометрических параметров кузовов);<br />
        //     -	Аттестован Министерством транспорта Российской Федерации как эксперт-техник, осуществляющий независимую техническую экспертизу транспортных средств (№ 1913 в государственном реестре Министерства юстиции РФ);<br />
        //     -	Являющимся представителем по Ульяновской области от  НП « Национальное Объединение Судебных Экспертов».  Стаж работы, в т.ч. экспертной, с 1998г.<br />
        //     -	Тренер по программе ICBT  2 модуль Международной школы бизнес-тренеров «Управление групповой динамикой»;<br />
        //     -	Сертифицирован удостоверением HR-профессионал, M-class тренинг.<br />`,
        //     "subtitleModalDescriptionSecond": `<b>Общественная деятельность: </b> <br />
        //                Некоммерческое партнерство «Палата судебных экспертов»,<br />
        //                 Центр землеустроительного проектирования, оценки земель и экспертизы на базе УлГАУ,<br />
        //                 ООО «Деловая Россия», <br />
        //                 УРО «Опора России», <br />
        //                 НП СРО «НОСЭ»,<br />
        //                 Представительство в Ульяновской области НП СРО «НОСЭ»,<br />
        //                 Партия «Единая Россия», <br />
        //                 «Центр финансово-кредитной поддержки», <br />
        //                 «Общественный совет при управлении Россреестра Ульяновской области», <br />
        //                 Благодарственное письмо за помощь в организации и проведении Российско – Китайского студенческого бизнес-инкубатора в г. Ульяновске (2017)<br />
        //                 Благодарственное письмо за помощь в реализации проекта «финансово-правовое объединение равных возможностей» от руководителя проекта, генерального директора Ассоциации предпринимателей «Люди дела»<br />
        //                 2022 Благодарственное письмо за многолетний добросовестный труд, высокие профессиональные достижения и в связи с десятилетием компании от Администрации города Ульяновска <br />
        //                 2022 Благодарственное письмо за большой вклад в развитие машиностроительного факультета и в связи с 65 – летним юбилеем Ульяновского государственного технического университета <br />
        //                 2021 Почётная грамота за личный вклад в развитие факультета агротехнологий, земельных ресурсов и пищевых производств и плодотворное сотрудничество в научно – образовательной деятельности от Ульяновского государственного аграрного университета имени П.А. Столыпина <br />`
        // },
        {
            id: 1,
            image: "/img/management_block/2.png",
            fullName: "Андриянова<br /> Светлана Юрьевна",
            descriptionCard: "Директор<br/>Член правления<br />Управляющий партнер",
            descriptionModal: `<b>Образование: </b><br/>
            1997-2002 гг  обучение в    ГОУ ВПО «Ульяновский государственный технический университет»<br/><br/>
            <b>Специальность по образованию:</b><br/>
            «Ульяновский государственный технический университет» Теплогазоснабжение и вентиляция<br/><br/>
            <b>Послевузовское образование:</b><br/>
            диплом о профессиональной переподготовки 022420567072 рег. номер ПП №57-002-23, выдан ЧОУ «Приуральский центр профессионального образования» по программе «Судебная экспертиза»;<br/><br/>
            <b>Какими языками владеет: </b>русский, английский<br/><br/>
            <b>Повышение квалификации:</b><br/>
                    	    &bull;&nbsp;удостоверение о краткосрочном повышении квалификации от ФГБОУ ВПО «УлГТУ» учебно-исследовательский центр «ИРАС» по программе «Ценообразование и сметное нормирование в строительстве»;<br/>
                    	    &bull;&nbsp;удостоверение о повышении квалификации №772405111019 от 29 ноября 2017 года, выдан ООО «Институт профессионального образования» по программе «Промышленное и гражданское строительство»;<br/>
                    	    &bull;&nbsp;удостоверение о повышении квалификации №770300022052 от 30 ноября 2021 года (регистрационный номер 21-13095), выдан ООО «Национальная академия современных технологий» по программе «Обследование, испытание и реконструкция зданий и сооружений»;<br/>
                    	    &bull;&nbsp;удостоверение о повышении квалификации №58736-2018 от 24.08.2018 по программе «Неразрушающие методы контроля металлов и сварных соединений (тепловой метод контроля)”, выдано ФГАУ «НУЦСК при МГТУ им. Н.Э. Баумана;<br/>
                    	    &bull;&nbsp;квалификационное удостоверение №001-58736 по методу теплового контроля 2 уровня, выдано ФГАУ «НУЦСК при МГТУ им. Н.Э. Баумана;<br/>
                    	    &bull;&nbsp;удостоверение №001-58736 о проверки знаний правил безопасности и федеральных норм и правил Ростехнадзора России «Правила устройства электроустановок», включена в национальный реестр специалистов НОПРИЗ (рег. номер П-086346 от 28.09.2018)<br/>`,
            subtitleModal: "Сертификат соответствия по судебно-экспертной специальности:",
            subtitleModalDescriptionSecond: `16.1 «Исследования строительных объектов и территории, функционально связанной с ними, с целью определения их стоимости»; <br/>
                            16.3 «Исследование домовладений с целью установления возможности их реального раздела между собственниками в соответствии с условиями, заданными судом; разработка вариантов указанного раздела»; <br/>
                            16.4 «Исследование проектной документации, строительных объектов в целях установления их соответствия требованиям специальных правил. Определение технического состояния, причин, условий, обстоятельств и механизма разрушения строительных объектов, частичной или полной утраты ими своих функциональных, эксплуатационных, эстетических и других свойств»;<br/>
                            16.5 «Исследование строительных объектов, их отдельных фрагментов, инженерных систем, оборудования и коммуникаций с целью установление объема, качества и стоимости выполненных работ, использованных материалов и изделий»;<br/>
                            16.6 «Исследования помещений жилых, административных, промышленных и иных зданий, поврежденных заливом (пожаром) с целью определения стоимости их восстановительного ремонта»;<br/><br />
                            <b>Общественная деятельность: </b> <br />
                            Включена в национальный реестр специалистов НОПРИЗ (рег. номер П-086346 от 28.09.2018) <br />
                            Некоммерческое партнерство «Палата судебных экспертов»,`,
        },
        {
            id: 2,
            image: "/img/management_block/3.png",
            fullName: "Павловская<br /> Елена Александровна",
            descriptionCard: "Член правления <br />Партнер",
            descriptionModal: `<b>Образование: </b><br/>
            2005-2007 гг  колледже при Ульяновском государственном университете <br/>
            2007-2011 гг ГОУ ВПО «Ростовский государственный  экономический университет (РИНХ») <br/><br/>

            <b>Специальность по образованию:</b><br/>
            «Банковское дело»<br/> 
            «Финансы и кредит»<br/><br/>
            <b>Какими языками владеет: </b>русский, английский<br/><br/>
            <b>Послевузовское образование:</b><br/>
                    	&bull;&nbsp;профессиональная переподготовка в ГОУ ВПО «Ульяновский государственный университет» по программе «Бухгалтер (средней квалификации)» (2010-2011)<br/>
                    	&bull;&nbsp;профессиональная переподготовка в ГОУ ВПО «Ульяновский государственный технический университет» по Президентской программе «Менеджмент в малом и среднем бизнесе» (2010-2011)<br/>
                    	&bull;&nbsp;Сертификат о прохождении повышение квалификации от ФГБОУ ВПО «Ульяновский государственный технический университет» учебно-исследовательский центр «ИРАС» по программе «Ценообразование и сметное нормирование в строительстве»;<br/>
                    	&bull;&nbsp;Квалификационный аттестат в области оценочной деятельности по направлению «Оценка бизнеса»;<br/>
                    	&bull;&nbsp;Квалификационный аттестат в области оценочной деятельности по направлению «Оценка недвижимости»;<br/>
                    	&bull;&nbsp;Квалификационный аттестат в области оценочной деятельности по направлению «Оценка движимого имущества»;<br/>
                        &bull;&nbsp;Сертифицирована в системе добровольной сертификации негосударственных судебных экспертов (РОСС RU.И597.04 НЯ00 при (НП «СУДЭКС» - РФЦСЭ при Министерстве юстиции РФ));<br/>
                        &bull;&nbsp;Cертификат соответствия по судебно-экспертной специальности: 34.1 «Судебная оценочная экспертиза»;<br/>`,
            subtitleModalDescriptionSecond: `<b>Общественная деятельность: </b> <br />
                            Член саморегулируемой организации оценщиков «Русское общество оценщиков»;<br />
                            Некоммерческое партнерство «Палата судебных экспертов» Ю.Г. Корухова» (СУДЭКС)`,
        },
        {
            id: 3,
            image: "/img/management_block/4.png",
            fullName: "Калмыкова<br /> Юлия александровна",
            descriptionCard: "Руководитель направления финансовых и товароведческих экспертиз",
            descriptionModal: `<b>Образование: </b><br/>
            2010-2015 гг  ФГБОУ ВПО «Ульяновская государственная сельскохозяйственная академия им. П.А. Столыпина»<br/><br/>
            <b>Специальность по образованию:</b><br/>
            «Бухгалтерский учет, анализ и аудит», квалификация «экономист»; «Ульяновская государственная сельскохозяйственная академия им. П.А. Столыпина»<br/><br/>
            <b>Послевузовское образование:</b><br/>
            2021 профессиональная переподготовка по программе «Судебная товароведческая экспертиза», квалификация «эксперт-товаровед» (АНО ДПО «Национальный университет современных технологий»);<br/>
            2022 профессиональная переподготовка с предоставлением права на ведение профессиональной деятельности в сфере «менеджмента» (ФГБОУ «Ульяновский государственный технический университет»);<br/><br/>
            <b>Какими языками владеет: </b>русский, английский<br/><br/>
            <b>Повышение квалификации:</b><br/>
                    	    •	2022 удостоверение о повышении квалификации №772416087483 от 09.09.2022 г. по профессиональной программе «Экспертиза в сфере закупок для государственных, муниципальных и корпоративных нужд в части приемки товаров, работ и услуг» (Негосударственное образовательное частное учреждение высшего образования «Московский финансово-промышленный университет «Синергия»);<br/>
                    	    •	свидетельство «Палата судебных экспертов имени Ю.Г. Корухова «СУДЭКС» от марта 2021 года об участии в семинаре (видеоконференции) «Практика решения задач товароведческой экспертизы»;<br/>
                    	    •	удостоверение о повышении квалификации федеральных государственных гражданских служащих №021600001157 от 25 июля 2019 г. в Федеральном государственном казенном образовательном учреждении высшего образования «Уфимский юридический институт Министерства внутренних дел Российской Федерации»;<br/>
                    	    •	удостоверение о повышении квалификации федеральных государственных гражданских служащих органов МВД России № 34547 от 27 сентября 2019 г. в Федеральном государственном казённом учреждении дополнительного профессионального образования «Всероссийский институт повышения квалификации сотрудников Министерства внутренних дел Российской Федерации»;<br/>`,
            subtitleModal: "Сертификат соответствия по судебно-экспертной специальности:",
            subtitleModalDescriptionSecond: `•    сертифицирована в системе добровольной сертификации судебных экспертов и экспертных организаций (РОСС RU.П1610.04 НЯ01, Союз лиц, осуществляющих деятельность в сфере судебной экспертизы и судебных экспертных исследований «Палата судебных экспертов имени Ю.Г. Корухова» (СУДЭКС)), сертификат соответствия № 015677 по судебно-экспертной специальности сроком действия до 22.11.2026 г. (период действия ранее выданного сертификата соответствия № 64.RU.D.49127 (РОСС RU.31594.04 ПАН0) с 19.02.2021 г. по 18.02.2024 г.): 19.1 «Исследование промышленных (непродовольственных) товаров, в том числе с целью проведения оценки»;<br/>
                            •	сертифицирована в системе добровольной сертификации негосударственных судебных (РОСС RU.Я2431.04ЕДУ0): сертификат соответствия по судебно-экспертной специальности: 25.1 «Исследование радиоэлектронных, электротехнических, электромеханических устройств бытового назначения»<br /><br />
                            <b>Общественная деятельность: </b> <br />
                            Некоммерческое партнерство «Палата судебных экспертов» Ю.Г. Корухова» (СУДЭКС)`,
        },
        {
            id: 4,
            image: "/img/management_block/5.png",
            fullName: "Пискунов<br /> Антон Евгеньевич",
            descriptionCard: "Руководитель направления криминалистических и комплексных экспертиз",
            descriptionModal: `<b>Образование: </b><br/>
            Диплом Саратовского юридического института МВД России серии ИВС №0317869<br/><br/>

            <b>Специальность по образованию:</b><br/>
            Судебная экспертиза<br/> <br/>
            <b>Какими языками владеет: </b>русский, английский<br/><br/>
            <b>Послевузовское образование:</b><br/>
                    	&bull;&nbsp;право производства дактилоскопических, баллистических, технико-криминалистических экспертиз документов, трасологических и портретных экспертиз, экспертиз холодного и метательного оружия (Свидетельство на право самостоятельного производства судебных экспертиз №010402);<br/>
                    	&bull;&nbsp;повышение квалификации в Волгоградской академии МВД России по дополнительной профессиональной программе «Повышение квалификации в форме стажировки экспертов ЭКП территориальных органов МВД России (имеющих право самостоятельного производства судебных экспертиз) по экспертной специальности «Технико-криминалистическая экспертиза документов» (Удостоверение о повышении квалификации №05 0001265 от 22.01.2016г.);<br/>
                    	&bull;&nbsp;повышение квалификации в ЧОУ ДПО «Институт непрерывного образования» по дополнительной профессиональной программе «Судебная товароведческая и стоимостная экспертиза промышленных (непродовольственных) товаров, электробытовой техники, средств связи» (Диплом о профессиональной переподготовке 373100279902); сертифицирован в системе добровольной сертификации судебных экспертов и экспертных организаций (РОСС RU.31792.04 ПСЭ0);<br/>
                    	&bull;&nbsp;сертификат  соответствия № PS 001785 по специальности;<br/>
                    	&bull;&nbsp;1.1.«Исследование почерка и подписей»;<br/>
                    	&bull;&nbsp;3.1.«Исследование реквизитов документов»;<br/>
                        &bull;&nbsp;5.1.«Идентификация человека по фотографическим изображениям»;<br/>
                        &bull;&nbsp;6.1.«Исследование следов человека и животных»;<br/>
                        &bull;&nbsp;6.2.«Исследование следов орудий, инструментов, механизмов, транспортных средств (транспортно-трасологическая идентификация);<br/>
                    	&bull;&nbsp;8.1.«Исследование огнестрельного оружия и патронов к нему»;<br/>
                    	&bull;&nbsp;8.2.«Исследование следов и обстоятельств выстрела»;<br/>
                        &bull;&nbsp;8.3 «Исследование холодного и метательного оружия»;<br/>
                        &bull;&nbsp;19.1 «Исследование промышленных (непродовольственных) товаров, в том числе с целью проведения оценки»;<br/>
                        &bull;&nbsp;25.1 «Исследование радиоэлектронных, электротехнических, электромеханических устройств бытового назначения»;<br/>`,
            subtitleModalDescriptionSecond: `<b>Общественная деятельность: </b> <br />
                        Некоммерческое партнерство «Палата судебных экспертов» Ю.Г. Корухова» (СУДЭКС)`,
        },
    ]);
    let [modalPersonInfo, setModalPersonInfo] = useState([]);

    useEffect(() => {
        setModalPersonInfo(data[0]);
    }, [data]);

    function openModal(id) {
        setModalPersonInfo(data[id]);
        setIsOpenModal(true);
    }

    return (
        <section className="management_block" id="management_block">
            <div className="container">
                <h2 className="title">Руководство</h2>
                <div className="management_all_cards">
                    {data.map((item, idx) => {
                        return (
                            <div
                                className="management_card"
                                onClick={() => {
                                    openModal(idx);
                                }}
                                key={idx}>
                                <img src={process.env.PUBLIC_URL + item.image} alt="руководство" />
                                <p className="subtitle" dangerouslySetInnerHTML={{ __html: item.fullName }}></p>
                                <p className="description" dangerouslySetInnerHTML={{ __html: item.descriptionCard }}></p>
                            </div>
                        );
                    })}
                </div>
            </div>
            <ModalWithContent isOpen={isOpenModal} setIsOpenModal={setIsOpenModal}>
                <div className="management_popup">
                    <div className="management_popup_human">
                        <div className="management_card">
                            <img src={process.env.PUBLIC_URL + modalPersonInfo.image} alt="руководство" />
                            <p className="subtitle" dangerouslySetInnerHTML={{ __html: modalPersonInfo.fullName }}></p>
                            <p className="description" dangerouslySetInnerHTML={{ __html: modalPersonInfo.descriptionCard }}></p>
                        </div>
                    </div>
                    <div className="management_popup_inform">
                        <p className="description" dangerouslySetInnerHTML={{ __html: modalPersonInfo.descriptionModal }}></p>
                        <p className="subtitle" dangerouslySetInnerHTML={{ __html: modalPersonInfo.subtitleModal }}></p>
                        <p className="description" dangerouslySetInnerHTML={{ __html: modalPersonInfo.subtitleModalDescriptionSecond }}></p>
                    </div>
                </div>
            </ModalWithContent>
        </section>
    );
}
