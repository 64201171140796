import {Link} from "react-router-dom";

export default function MainBlockCommand(props) {
    return (
        <section className="main_block main_block_command">
            <div className="container">
                <div className="bread_crumbs">
                    <div>
                        <Link to="/">Главная</Link>
                    </div>
                    <div>{'>'}</div>
                    <div>Команда</div>
                </div>
                <h1>
                    <span className="title main_title">Команда</span>
                </h1>
            </div>
        </section>
    )
}
