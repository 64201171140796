import MainBlock from "../block/main_blocks/block_news_detail";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import newsData from "../data/news"
import AllNewsBlock from "../block/all_news_block";

export default function NewsDetail({settem, setIsOpenPopUp}) {
    let {newsId} = useParams();
    let [data, setData] = useState({});
    useEffect(() => {
        let filteredNews = newsData.filter((item) => item.id === Number(newsId));
        if (filteredNews.length === 1) {
            setData(filteredNews[0]);
        }
    });
    return(
        <main>
            <MainBlock title={data.title} />
            <AllNewsBlock news={data} />
        </main>
    )
}
