import { useEffect, useState } from 'react';
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps";
import { json } from 'react-router-dom';

export default function ContactBlock(params) {
    const defaultState = {
        center: [54.328806, 48.395874],
        zoom: 15,
    };
    const text1 = "<b>Головной офис в Ульяновске</b> <br> Координаты: 54.328806, 48.395874"
    const [map, setmap] = useState(defaultState);
    const [text, settext] = useState(text1);
    let [isActiveFirstCard, setIsActiveFirstCard] = useState(true); 
        
        
    const point2 = {
        center:[55.710869, 37.594090],
        zoom: 15,
    };

    const text2 = "<b>Офис в Москве</b> <br> Координаты: 55.710869, 37.594090";

    function downloadRequisits() {
        const pdfUrl = "requisites.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "requisites.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <section className="contact_block">
            <div className="container">
                <h2 className="title">работаем по всей территории России и ближнего зарубежья</h2>
                <div className="contact_map_inform">
                    <YMaps className="map">
                        <Map height={"427px"} width={"100%"} state={map}>
                        <Placemark
                            modules={[
                                "geoObject.addon.balloon",
                            ]}
                            geometry={map.center}
                            options={{
                                iconLayout: "default#image",
                                iconImageHref: process.env.PUBLIC_URL + "/img/contact_block/map_icon.png",
                                iconImageSize: [55, 55],
                                iconImageOffset: [-30, -50],
                            }}
                            properties={{
                                balloonContent: `<div id="driver-2" class="driver-card">${text}</div>`,
                            }}
                        />
                        </Map>
                    </YMaps>
                    <div className="contact_inform">
                        <div className="contact_inform_card Ulyanovsk_contacts">
                            <p className="description contact_inform_title" style={{background: (isActiveFirstCard ? "#497af9" : "")}}>Головной офис в Ульяновске</p>
                            <div className="contact_inform_card_detail">
                                <div className="contact_inform_card_detail_block">
                                    <div>
                                        <p className="contact_inform_inscription">Адрес офиса</p>
                                        <p className="description">г. Ульяновск,<br/>
                                        ул Федерации 89А, 3 этаж</p>
                                    </div>
                                    <div>
                                        <p className="contact_inform_inscription">Телефон: </p>
                                        <a href="tel:89372757345" className="subtitle">+7 (937) 275-73-45 </a>
                                    </div>
                                    <div>
                                        <p className="contact_inform_inscription">E-mail: </p>
                                        <a href="mailto:sudex@rusnekc.ru" className="description">sudex@rusnekc.ru</a>
                                    </div>
                                    <div>
                                        <p className="contact_inform_inscription">Факс</p>
                                        <a href="tel:88422271165" className="description">8 842 227-11-65</a>
                                    </div>
                                    <div>
                                        <p className="contact_inform_inscription">График работы:</p>
                                        <p className="description">пн-пт с 8:00 до 17:00</p>
                                    </div>
                                </div>
                                <button className="button_contact white_button button" onClick={() => {setmap(defaultState); settext(text1); setIsActiveFirstCard(true); }}>
                                    Посмотреть на карте
                                    <img src={process.env.PUBLIC_URL + "/img/contact_block/button_arrow.png"} alt="стрелка на кнопке" />
                                </button>  
                            </div>
                        </div>
                        <div className="contact_inform_card Moscow_contacts">
                            <p className="description contact_inform_title" style={{background: (!isActiveFirstCard ? "#497af9" : "")}}>Офис в Москве</p>
                            <div className="contact_inform_card_detail">
                                <div className="contact_inform_card_detail_block">
                                    <div>
                                        <p className="contact_inform_inscription">Адрес офиса</p>
                                        <p className="description">г. Москва, <br />
                                        2-й Донской проезд,  д.10,  строение 2</p>
                                    </div>
                                    <div>
                                        <p className="contact_inform_inscription">Телефон: </p>
                                        <a href="tel:88007551980" className="subtitle">+7 (800) 755-19-80 </a><br/>
                                        <a href="tel:84993914726" className="subtitle">+7 (499) 391-47-26 </a>
                                    </div>
                                    <div>
                                        <p className="contact_inform_inscription">E-mail: </p>
                                        <a href="mailto:sudex@rusnekc.ru" className="description">sudex@rusnekc.ru</a>
                                    </div>
                                    <div>
                                        <p className="contact_inform_inscription">Факс</p>
                                        <a href="tel:88422271165" className="description">8 842 227-11-65</a>
                                    </div>
                                    <div>
                                        <p className="contact_inform_inscription">График работы:</p>
                                        <p className="description">пн-пт с 8:00 до 17:00</p>
                                    </div>
                                </div>
                                <button className="button_contact white_button button"  onClick={() => {setmap(point2); settext(text2); setIsActiveFirstCard(false); }}>
                                    Посмотреть на карте
                                    <img src={process.env.PUBLIC_URL + "/img/contact_block/button_arrow.png"} alt="стрелка на кнопке" />
                                </button>  
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button style={{marginTop: '20px'}} onClick={() => {downloadRequisits()}}>Скачать реквизиты</button>
                </div>
            </div>
        </section>
    )
}
