import { useState } from "react";
import PopUp from "./pop-up";

export default function FourthBlock({settem, setIsOpenPopUp}) {

    return (
        <section className="fourth_block">
            <div className="container">
                <h2 className="fourth_title title">Мы поможем найти быстрый и эффективный способ для решения конкретной задачи</h2>
                <div className="fourth-inform">
                    <div className="fourth_black_block">
                        <p className="fourth_description description">
                            Мы уже сделали более 10 000 первоклассных экспертиз и продолжаем постоянно совершенствовать нашу систему качества
                        </p>
                        <img src={process.env.PUBLIC_URL + "/img/fourth_block/arrow.png"} alt="стрелка" />
                    </div>
                    <div className="fourth_big_block">
                        <div className="fourth_big_block_top">
                            <div className="fourth_big_block_photo">
                                <img className="fourth_block_computer_photo" src={process.env.PUBLIC_URL + "/img/fourth_block/picture.png"} alt="картинка" />
                                <img className="fourth_block_mobile_photo" src={process.env.PUBLIC_URL + "/img/fourth_block/picture_mobile.png"} alt="картинка" />
                            </div>
                            <div className="fourth_big_block_inform">
                                <div className="fourth_black_block_adaptiv">
                                    <p className="fourth_description description">
                                        Мы уже сделали более 10 000 первоклассных экспертиз и продолжаем постоянно совершенствовать нашу систему качества
                                    </p>
                                    <img src={process.env.PUBLIC_URL + "/img/fourth_block/arrow.png"} alt="стрелка" />
                                </div>
                                <div>
                                    <p className="subtitle">Поможем в определении цены, оптимальных условиях сделки и оценим риски.</p>
                                    <p className="description">Мы соблюдаем нормативные требования для ведения деятельности, что еще больше гарантирует качество и честность оценки.</p>
                                    <button className="blue_button fourth_button button" onClick={() => {setIsOpenPopUp(true); settem("Оставьте заявку, мы перезвоним и обсудим детали")}}>
                                        Получить бесплатную консультацию
                                        <img src={process.env.PUBLIC_URL + "/img/fourth_block/button_arrow.png"} alt="стрелка на кнопке" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <details className="drop_down_block fourth_big_block_questions" name="fourth_big_block_questions">
                            <summary>
                                <div className="fourth_big_block_link">
                                    <div className="fourth_big_block_link_left">
                                        <img src={process.env.PUBLIC_URL + "/img/fourth_block/01.png"} alt="номер ссылки" />
                                        <p className="subtitle subtitle_link">НЕ ЗНАЕТЕ КАК СФОРМУЛИРОВАТЬ ВОПРОСЫ?</p>
                                    </div>
                                    <div className="link_arrow_container">
                                        <img className="link_arrow" src={process.env.PUBLIC_URL + "/img/fourth_block/link_arrow.png"} alt="ссылка стрелка" />
                                    </div>
                                </div> 
                            </summary>
                            <div className="description fourth_big_block_answers">
                                Наши эксперты готовы помочь с формулировкой вопросов!
                            </div>
                        </details>
                        <details className="drop_down_block fourth_big_block_questions" name="fourth_big_block_questions">
                            <summary>
                                <div className="fourth_big_block_link">
                                    <div className="fourth_big_block_link_left">
                                        <img src={process.env.PUBLIC_URL + "/img/fourth_block/02.png"} alt="номер ссылки" />
                                        <p className="subtitle subtitle_link">Не уверены, что экспертиза будет в вашу пользу?</p>
                                    </div>
                                    <div className="link_arrow_container">
                                        <img className="link_arrow" src={process.env.PUBLIC_URL + "/img/fourth_block/link_arrow.png"} alt="ссылка стрелка" />
                                    </div>
                                </div> 
                            </summary>
                            <div className="description fourth_big_block_answers">
                                Мы гарантируем объективную и профессиональную экспертизу!
                            </div>
                        </details>
                        <details className="drop_down_block fourth_big_block_questions" name="fourth_big_block_questions">
                            <summary>
                                <div className="fourth_big_block_link">
                                    <div className="fourth_big_block_link_left">
                                        <img src={process.env.PUBLIC_URL + "/img/fourth_block/03.png"} alt="номер ссылки" />
                                        <p className="subtitle subtitle_link">Хотите проверить качество экспертизы, но не знаете как?</p>
                                    </div>
                                    <div className="link_arrow_container">
                                        <img className="link_arrow" src={process.env.PUBLIC_URL + "/img/fourth_block/link_arrow.png"} alt="ссылка стрелка" />
                                    </div>
                                </div> 
                            </summary>
                            <div className="description fourth_big_block_answers">
                                Мы можем провести рецензию экспертизы.
                            </div>
                        </details>
                    </div>
                </div>
            </div>
        </section>
    )
}
