export default function CareerBlock(params) {
    return (
        <section className="career_block">
            <div className="career_menu">
                <a className="career_menu_link" href="#career_advantages">Преимущества</a>
                <a className="career_menu_link" href="#resume_block">Стать частью команды</a>
                <a className="career_menu_link" href="#gallery_block">Фотогаллерея</a>
            </div>
            <div className="container">
                <div className="career_advantages" id="career_advantages">
                    <h2 className="title">преимущества работы с нами</h2>
                    <div className="career_all_cards">
                        <div className="career_card n_one">
                            <div>
                                <p className="career_title">карьера</p>
                            </div>
                            <div>
                                <p className="description">
                                    Мы предоставляем уникальные возможности для профессионального и карьерного роста. Многоуровневая структура обеспечивает сотрудникам возможность постоянного развития и движения вперед.
                                </p>
                                <p className="subtitle">Все зависит только от Вас!</p>
                            </div>
                        </div>
                        <div className="career_card n_two">
                            <div>
                                <p className="career_title">наставничество и обучение</p>
                            </div>
                            <div>
                                <p className="description description_one">
                                    Профессиональные компетенции сотрудников чрезвычайно важны для компании и являются основой ее успеха.
                                </p>
                                <p className="description">
                                    Мы уделяем особое внимание развитию системы наставничества, которая способствует повышению профессионализма и карьерному росту сотрудников.
                                </p>
                            </div>
                        </div>
                        <div className="career_card n_three">
                            <div className="n_three_img">
                                <img src={process.env.PUBLIC_URL + "/img/career_block/arrow.png"} alt="картинка" />
                            </div>
                            <div>
                                <p className="career_title">масштабные проекты</p>
                                <p className="description">
                                    Каждый сотрудник АНО НЭКЦ "СУДЭКС" причастен к созданию масштабных проектов, которые мы реализовываем с крупнейшими компаниями нашей страны.
                                </p>
                                <p className="subtitle">
                                    Мы ценим вклад каждого в достижение поставленных целей!
                                </p>
                            </div>
                        </div>
                        <div className="career_card n_four">
                            <p className="career_title">Уверенность 
                            в будущем</p>
                            <p className="description">
                                АНО НЭКЦ "СУДЭКС" - компания больших возможностей,  давно зарекомендовавшая себя на рынке!
                            </p>
                            <p className="description">
                                Мы занимаем лидирующие позиции, демонстрируем высокие показатели эффективности по всем направлениям деятельности и являемся надежным работодателем.
                            </p>
                            <p className="subtitle">
                                Мы одна команда - мы одна семья!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}