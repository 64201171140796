import { useState } from "react";

export default function HistoryBlock(params) {
    let [items, setItems] = useState([
        {
            "id": 0,
            "year": 2024,
            "descriptionPreview": "Все наши эксперты успешно прошли сертификацию",
            "description": "В этом году мы достигли значительного успеха: все наши эксперты успешно прошли сертификацию, подтвердив свою квалификацию и профессионализм. Этот важный шаг подчеркивает нашу приверженность высоким стандартам качества и надежности. Мы гордимся нашей командой и уверены, что их знания и опыт продолжат приносить пользу нашим клиентам и партнерам в будущем.",
            "image": "/img/history/1.png",
            "isActive": true
        },
        {
            "id": 1,
            "year": 2023,
            "descriptionPreview": "Завершили многолетний труд по решению оплаты судебным экспертам в рф",
            "description": "Совместно с «Опорой России» НП Палаты судебных экспертов имени Корухова и другими партнерами завершили многолетний труд по решению оплаты судебным экспертам в рф путем внесения на депозит судов денежных средств на оплату судебной экспертизы восстановив тем самым конститунционные права работников этой сферы (Постановление Конституционного Суда РФ от 20.07.2023 N 43-П).",
            "image": "/img/history/2.png",
            "isActive": false
        },
        {
            "id": 2,
            "year": 2022,
            "descriptionPreview": "Изменили наименование автономной некоммерческой организации",
            "description": "8 апреля изменили наименование автономной некоммерческой организации на «Национальный экспертно-криминалистический центр «СУДЭКС» (АНО НЭКЦ «СУДЭКС»).  Компания в лице  Председателя правления получил благодарственное письмо от Администрации города Ульяновска за многолетний добросовестный труд и высокие профессиональные достижения.  В составе группы компаний заняли 34 место в ренкинге делового потенциала российских оценочных групп.  Стали членом палаты судебных экспертов имени Ю.Г. Корюхова «СУДЭКС» и были включены в Реестр Членов «СУДЭКС» № 217.",
            "image": "/img/history/3.png",
            "isActive": false
        },
        {
            "id": 3,
            "year": 2021,
            "descriptionPreview": "Получили почетную грамоту от Ульяновского аграрного университета за личный вклад в развитие факультета агротехнологий",
            "description": "Компания в лице Председателя правления получил почетную грамоту от Ульяновского аграрного университета им. П.А. Столыпина за личный вклад в развитие факультета агротехнологий, земельных ресурсов и пищевых производств.  В составе группы компаний заняли 31 место в ренкинге делового потенциала российских оценочных групп.",
            "image": "/img/history/4.png",
            "isActive": false
        },
        {
            "id": 4,
            "year": 2020,
            "descriptionPreview": "Приняли участие в Областном общественном конкурсе «Общественное признание – 2020»",
            "description": "Приняли участие в Областном общественном конкурсе «Общественное признание – 2020» в номинации «НКО года».",
            "image": "/img/history/5.png",
            "isActive": false
        },
        {
            "id": 5,
            "year": 2019,
            "descriptionPreview": "Продолжили успешную деятельность и укрепили свои позиции на рынке.",
            "description": "Продолжили успешную деятельность и укрепили свои позиции на рынке.",
            "image": "/img/history/6.png",
            "isActive": false
        },
        {
            "id": 6,
            "year": 2018,
            "descriptionPreview": "Открыли Общественный совет судебных экспертов Ульяновской области",
            "description": "Открыли Общественный совет судебных экспертов Ульяновской области при «Палате судебных экспертов имени Ю.Г. Корухова».  Стали официальными представителями в Ульяновской области НП СРО «Национальное объединение судебных экспертов».  Создали лабораторию криминалистических экспертиз и закупили новое оборудование.  В составе группы компаний стали победителями в конкурсе «Бренд Года» за динамичное развитие.",
            "image": "/img/history/7.png",
            "isActive": false
        },
        {
            "id": 7,
            "year": 2017,
            "descriptionPreview": "Получили благодарственное письмо за помощь в организации и проведении Российско-Китайского студенческого бизнес-инкубатора",
            "description": "Компания в лице Председателя правления получил благодарственное письмо за помощь в организации и проведении Российско-Китайского студенческого бизнес-инкубатора в г. Ульяновске.",
            "image": "/img/history/8.png",
            "isActive": false
        },
        {
            "id": 8,
            "year": 2016,
            "descriptionPreview": "Выделили из отдела оценки лабораторию товароведческих и финансово-экономических экспертиз",
            "description": "Выделили из отдела оценки лабораторию товароведческих и финансово-экономических экспертиз. Приобрели комплекс криминалистических инструментов: микроскоп для более точных исследований.",
            "image": "/img/history/9.png",
            "isActive": false
        },
        {
            "id": 9,
            "year": 2015,
            "descriptionPreview": "Из отдела строительно-технических экспертиз сформировалась Лаборатория строительно-технических экспертиз",
            "description": "Из отдела строительно-технических экспертиз сформировалась Лаборатория строительно-технических экспертиз.  Приобрели геодезический комплекс GPS/ГЛОНАСС приемник SOUTH S82V, тепловизор Testo-885-2 и тахеометр Spectra Precision",
            "image": "/img/history/10.png",
            "isActive": false
        },
        {
            "id": 10,
            "year": 2014,
            "descriptionPreview": "Выделили отдельное направление судебной и досудебной экспертизы",
            "description": "Выделили отдельное направление судебной и досудебной экспертизы. 14 января была образована новая компания АНО НЭКЦ.  Создали компанию и запустили лабораторию автотехнических экспертиз.  Приобрели современное оборудование: Launch x431 Pro сканер для диагностики автомобилей,  3D промер геометрии кузова и его проёмов,  видеоэндоскоп с управлением на 180 градусов,  автомобильный диагностический сканер.",
            "image": "/img/history/11.png",
            "isActive": false
        },
        {
            "id": 11,
            "year": 2013,
            "descriptionPreview": "Расширили географию наших услуг, начав работу в Москве и Кабардино-Балкарской Республике",
            "description": "Расширили географию наших услуг, начав работу в Москве и Кабардино-Балкарской Республике.",
            "image": "/img/history/12.png",
            "isActive": false
        },
        {
            "id": 12,
            "year": 2012,
            "descriptionPreview": "Расширили производство экспертиз по Поволжью",
            "description": "Расширили производство экспертиз по Поволжью, что позволило нам охватить больше регионов и клиентов.",
            "image": "/img/history/13.png",
            "isActive": false
        },
        {
            "id": 13,
            "year": 2011,
            "descriptionPreview": "Прошли сертификацию экспертов и компании в области судебных экспертиз",
            "description": "Прошли сертификацию экспертов и компании в области судебных экспертиз, что подтвердило наш профессионализм и надежность.",
            "image": "/img/history/14.png",
            "isActive": false
        },
        {
            "id": 14,
            "year": 2010,
            "descriptionPreview": "Начало активной деятельности и выполнение первых судебных экспертиз",
            "description": "Начало активной деятельности и выполнение первых судебных экспертиз. В этом же году была создана группа компаний, что позволило нам расширить спектр услуг.",
            "image": "/img/history/15.png",
            "isActive": false
        },
        {
            "id": 15,
            "year": 2009,
            "descriptionPreview": "Основание компании в рамках проведения независимых экспертиз",
            "description": "Основание компании в рамках проведения независимых экспертиз, что стало началом нашего пути в сфере судебных экспертиз.",
            "image": "/img/history/16.png",
            "isActive": false
        },
    ]);

    function setActive(id) {
        let newState = items.map(obj => {
            if (obj.id === id) {
                return {...obj, isActive: true};
            }
            return {...obj, isActive: false};
        });
        setItems(newState);
    }

    return (
        <section className="history_block" id="history_block">
            <div className="container">
                <h2 className="title">История компании</h2>
                <div className="history_all_cards">
                    {items.map((item, idx) => {
                        return (
                            <div 
                                key={idx} 
                                className={`history_card ${item.isActive ? "active_card" : ""}`} 
                                onClick={() => {setActive(item.id)}}
                            >
                                <div className="history_card_arrow">
                                    <img 
                                        src={process.env.PUBLIC_URL + `/img/history/arrow_${item.isActive ? "up" : "down"}_card.png`} 
                                        alt="" 
                                    />
                                </div>
                                <div className="history_block_year_text_img">
                                    <div className="history_block_year_text">
                                        <p className="history_block_year">{item.year}</p>
                                        <p className="description history_block_text">{item.isActive ? item.description : item.descriptionPreview}</p>
                                    </div>
                                    <div className="history_block_img">
                                        <img src={process.env.PUBLIC_URL + item.image} alt="картинка" />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}
