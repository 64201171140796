import MainBlock from "../block/main_blocks/block_command";
import CommandBlock from "../block/command_block";
import HistoryBlock from "../block/history_block";
import ManagementBlock from "../block/management_block";
import MissionBlock from "../block/mission_block";
import PresentationBlock from "../block/presentation_block";
import ExpertiseBlock2 from "../block/expertise_block_2";
import { useEffect } from "react";

export default function Command({settem, setIsOpenPopUp}) {
    useEffect(() => {
        document.title = 'Команда';
      }, []);
    return(
        <main>
            <MainBlock />
            <CommandBlock />
            <HistoryBlock />
            <ManagementBlock />
            <MissionBlock />
            <PresentationBlock />
            <ExpertiseBlock2 settem={settem} setIsOpenPopUp={setIsOpenPopUp}/>
        </main>
    )
}