import MainBlock from "../block/main_blocks/block_equipment";
import EquipmentBlock from "../block/equipment_block";
import { useEffect } from "react";

export default function Equipment({settem, setIsOpenPopUp}) {
    useEffect(() => {
        document.title = 'Оборудование';
      }, []);
    return(
        <main>
            <MainBlock />
            <EquipmentBlock />
        </main>
    )
}