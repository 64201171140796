export default function EquipmentBlock(params) {
    return (
        <section className="equipment_block">
            <div className="container">
                <div className="equipment_block_all_cards">
                    <div className="equipment_block_card">
                        <p className="equipment_block_title">Тепловизор Testo-885-2</p>
                        <div className="equipment_photo_inform">
                            <div className="equipment_block_card_photo">
                                <img src={process.env.PUBLIC_URL + "/img/equipment_block/1.png"} alt="картинка" />
                            </div>
                            <div className="equipment_block_card_inform">
                                <p className="equipment_block_card_description">
                                    •   <b>Профессиональный тепловизор:</b> детектор 320 х 240 пикселей, 640х480 с технологией SuperResolution (опция), температурная чувствительность {'<'} 30 мK 
                                </p>
                                <p className="equipment_block_card_description">
                                    •	<b>Расширенный диапазон</b> измерения низких температур до -30 °С, измерение высоких температур до 1200 °C (опция) <br/>
                                </p>
                                <p className="equipment_block_card_description">
                                    •	<b>Технология</b> SiteRecognition, создание панорамных снимков, радиометрическое видео
                                </p>
                                <p className="equipment_block_card_description">
                                    •	<b>Внесен в Государственный Реестр средств измерений РФ</b>
                                </p>
                                <p className="equipment_block_card_description">
                                    •	<b>Тепловизор в удобном дизайне видеокамеры</b> с вращающейся рукояткой и откидным дисплеем, детектором с высокой разрешающей способностью, с температурной чувствительностью {'<'} 30 мК, стандартным широкоугольным объективом (30°), встроенной цифровой камерой с мощной светодиодной подсветкой, функцией записи голосовых комментариев посредством гарнитуры, возможностью отображения распределения поверхностной влажности и опцией измерения высоких температур — всё необходимое для профессиональной термографии.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="equipment_block_card">
                        <p className="equipment_block_title">Геодезический комплекс GPS/Глонасс приемник SOUTH S82V</p>
                        <div className="equipment_photo_inform right_equipment_photo_inform">
                            <div className="equipment_block_card_inform">
                                <p className="equipment_block_card_description">
                                •   <b>Многофункциональный геодезический ГЛОНАСС/GPS приемник.</b>
                                </p>
                                <p className="equipment_block_card_description">
                                •	<b>Удобный моноблок</b> в пластиковом исполнении, со встроенной GPS/ГЛОНАСС платой Trimble BD970 – 220 каналов. 
                                </p>
                                <p className="equipment_block_card_description">
                                •	<b>Применим для любых геодезических работ под открытым небом</b>
                                </p>
                                <p className="equipment_block_card_description">
                                •	<b>Подходит как для топографии так и для геодзических изысканий под строительство.</b> И многих других геодезических, геологических, геофизических и любых других задач, где нужно точное позиционирование. 
                                </p>
                                <p className="equipment_block_card_description">
                                •   <b>GPS</b> — спутниковая навигационная система для определения местоположения любых точек, эксплуатируемая и управляемая Министерством Обороны США, предоставляющая услуги, как военным, так и гражданским пользователям.
                                </p>
                            </div>
                            <div className="equipment_block_card_photo">
                                <img src={process.env.PUBLIC_URL + "/img/equipment_block/2.png"} alt="картинка" />
                            </div>
                        </div>
                    </div>
                    <div className="equipment_block_card">
                        <p className="equipment_block_title">Толщиномер покрытий Condtrol CoFN</p>
                        <div className="equipment_photo_inform">
                            <div className="equipment_block_card_photo">
                                <img src={process.env.PUBLIC_URL + "/img/equipment_block/3.png"} alt="картинка" />
                            </div>
                            <div className="equipment_block_card_inform">
                                <p className="equipment_block_card_description">
                                    •   <b>Толщиномер покрытий CoFN CONDTROL</b> – компактный и простой измеритель со встроенным в корпус датчиком для быстрого и точного измерения толщины лакокрасочных, гальванических и защитных полимерных покрытий.
                                </p>    
                                <p className="equipment_block_card_description">    
                                    •   <b>Прибор двойного назначения для измерений на стали и цветных металлах</b> имеет функцию автоматического определения подложки (магнитный/немагнитный слой) и не нуждается в калибровке.
                                </p> 
                                <p className="equipment_block_card_description">
                                    •   В качестве дополнительной опции предусмотрена возможность передачи данных в ПК.
                                </p> 
                                <p className="equipment_block_card_description">
                                    •   <b>Преимущества толщиномера покрытий CoFN CONDTROL</b>
                                </p><br/>
                                <p className="equipment_block_card_description">
                                    - при измерении величины покрытий, выполненными жидкими или порошковыми красками;
                                </p>
                                <p className="equipment_block_card_description">    
                                    - в автомобильном производстве и при ремонте автомобилей;
                                </p>
                                <p className="equipment_block_card_description">    
                                    - для входного контроля материалов, технологических процессов и контроля готовой продукции;
                                </p>
                                <p className="equipment_block_card_description">    
                                    - для проектирования и экспертных оценок в лабораториях и в полевых условиях.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="equipment_block_card">
                        <p className="equipment_block_title">Анеометр Testo 410-2</p>
                        <div className="equipment_photo_inform right_equipment_photo_inform">
                            <div className="equipment_block_card_inform">
                                <p className="equipment_block_card_description">
                                    •	<b>Расчет среднего значения</b> по времени замеров
                                </p>
                                <p className="equipment_block_card_description">
                                    •	<b>Функция Hold</b> для фиксации текущего значения и отображение 
                                    макс./мин. значений
                                </p>
                                <p className="equipment_block_card_description">
                                    •	<b>Расчет температуры охлаждения ветром</b> для применения 
                                    на открытом воздухе
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	<b>Измерение влажности воздуха</b> долговечным сенсором влажности Testo
                                </p> 
                                <p className="equipment_block_card_description">
                                    •	<b>Внесён в Государственный реестр средств измерений РФ</b>
                                </p>    
                                <p className="equipment_block_card_description">    
                                    •	Testo 410-2 анемометр <b>со встроенной крыльчаткой,</b> сенсором влажности Testo, NTC сенсором температуры, вкл. защитную крышку, батарейки и заводской протокол калибровки
                                </p>
                            </div>
                            <div className="equipment_block_card_photo">
                                <img src={process.env.PUBLIC_URL + "/img/equipment_block/4.png"} alt="картинка" />
                            </div>
                        </div>
                    </div>
                    <div className="equipment_block_card">
                        <p className="equipment_block_title">Термоанемометр Testo 405-V1</p>
                        <div className="equipment_photo_inform">
                            <div className="equipment_block_card_photo">
                                <img src={process.env.PUBLIC_URL + "/img/equipment_block/5.png"} alt="картинка" />
                            </div>
                            <div className="equipment_block_card_inform">
                                <p className="equipment_block_card_description">
                                <b>Измеряет скорость воздушного потока, температуру и рассчитывает объемный расход</b> без функции  усреднения измеренный значений
                                </p>
                                <p className="equipment_block_card_description">
                                •	Расчет объемного расхода от 0 до 99990 м³/ч
                                </p>
                                <p className="equipment_block_card_description">
                                •	Измерения в воздуховодах и на вентиляционных решетках
                                </p>
                                <p className="equipment_block_card_description">
                                •	Держатель для воздуховода в комплекте
                                </p>
                                <p className="equipment_block_card_description">
                                •	Максимальная длина зонда 300 мм позволяет измерять в больших воздуховодах
                                </p>
                                <p className="equipment_block_card_description">
                                •	Простой в работе, всего одна кнопка
                                </p>
                                <p className="equipment_block_card_description">
                                •	Функция автоматического выключения
                                </p>
                                <p className="equipment_block_card_description">
                                •	Большой и удобный для считывания данных поворотный дисплей
                                </p>
                                <p className="equipment_block_card_description">
                                •	Точный NTC сенсор
                                </p>
                                <p className="equipment_block_card_description">
                                •	Один поворот колпачка и датчик надежно защищен
                                </p>
                                <p className="equipment_block_card_description">
                                <b>No 17273-11 в Госреестре средств измерений</b>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="equipment_block_card">
                        <p className="equipment_block_title">Термогигрометр Testo 608-H2</p>
                        <div className="equipment_photo_inform right_equipment_photo_inform">
                            <div className="equipment_block_card_inform">
                                <p className="equipment_block_card_description">
                                    •	<b>Непрерывное отображение температуры, влажности и температуры точки росы</b>
                                    </p>
                                    <p className="equipment_block_card_description">
                                    •	Вывод макс./мин. значений; мониторинг состояния батареи
                                    </p>
                                    <p className="equipment_block_card_description">
                                    •	LED-сигнализация для оповещения при превышении предельных значений
                                    </p>
                                    <p className="equipment_block_card_description">
                                    •	Высокий уровень точности ± 2 %ОВ
                                    </p>
                                    <p className="equipment_block_card_description">
                                    •	<b>Внесён в Государственный реестр средств измерений РФ</b>
                                    </p>
                                    <p className="equipment_block_card_description">
                                    •	Гигрометр testo 608 H-2 для измерения влажности/температуры/температуры точки росы, 
                                    с LED-сигнализацией, батарейкой и протоколом калибровки
                                </p>
                            </div>
                            <div className="equipment_block_card_photo">
                                <img src={process.env.PUBLIC_URL + "/img/equipment_block/6.png"} alt="картинка" />
                            </div>
                        </div>
                    </div>
                    <div className="equipment_block_card">
                        <p className="equipment_block_title">Измеритель температуры Testo 810</p>
                        <div className="equipment_photo_inform">
                            <div className="equipment_block_card_photo">
                                <img src={process.env.PUBLIC_URL + "/img/equipment_block/7.png"} alt="картинка" />
                            </div>
                            <div className="equipment_block_card_inform">
                                <p className="equipment_block_card_description">
                                    •   <b>К-измерение с 1-точечным лазерным целеуказателем и оптикой 6:1</b>
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	Отображение дифференциальной температуры между температурой воздуха и поверхности
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	Функция Hold и отображение мин./макс. значений
                                </p>
                                <p className="equipment_block_card_description">   
                                    •	Подсветка дисплея
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	<b>Внесён в Государственный реестр средств измерений РФ</b>
                                </p>
                                <p className="equipment_block_card_description">
                                    •	testo 810, 2-х канальный прибор измерения температуры с ИК-термометром, лазерным целеуказателем и встроенным NTC термометром воздуха, вкл. защитную крышку, батарейки и заводской протокол калибровки
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="equipment_block_card">
                        <p className="equipment_block_title">Лазерный нивелир PHANTON 2D SET</p>
                        <div className="equipment_photo_inform right_equipment_photo_inform">
                            <div className="equipment_block_card_inform">
                                <p className="equipment_block_card_description">
                                    <b>Самонивелирующийся лазерный уровень ADA Phantom 2D</b> 
                                </p>
                                <p className="equipment_block_card_description">
                                    •	Строит  две плоскости вертикальную и горизонтальную с достаточно большой развёрткой лучей 
                                    для данного класса. 
                                </p>
                                <p className="equipment_block_card_description">
                                    <b>Технические характеристики</b>
                                </p><br/>
                                <p className="equipment_block_card_description">
                                    •	Точность при горизонтальной установке, мм/м: 2/10
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	Диапазон работы компенсатора, °: ± 3 
                                </p>
                                <p className="equipment_block_card_description">        
                                    •	Дальность работы с приемником (макс.), м: 70 
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	Дальность работы без приёмника, м: 20* (зависит от степени освещённости) 
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	Класс пыле и влагозащиты: IP 54 
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	Количество лазерных диодов, шт: 2 
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	Длина волны лазерного диода, нм: 635 
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	Класс лазерного излучателя: 2 
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	Батареи/напряжение, Шт. х Тип/Вольт: 3 x 1.5 V AA 
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	Диапазон рабочих температур, °С: -10 до +45 
                                </p>
                            </div>
                            <div className="equipment_block_card_photo">
                                <img src={process.env.PUBLIC_URL + "/img/equipment_block/8.png"} alt="картинка" />
                            </div>
                        </div>
                    </div>
                    <div className="equipment_block_card">
                        <p className="equipment_block_title">Видеоэндоскоп с управлением на 180 град.</p>
                        <div className="equipment_photo_inform">
                            <div className="equipment_block_card_photo">
                                <img src={process.env.PUBLIC_URL + "/img/equipment_block/9.png"} alt="картинка" />
                            </div>
                            <div className="equipment_block_card_inform">
                                <p className="equipment_block_card_description">
                                    <b>Технические видеоэндоскопы предназначены для визуального контроля в нестационарных условиях труднодоступных мест</b> в условиях недостаточной освещенности с применением телевизионного канала.
                                </p><br/>
                                <p className="equipment_block_card_description">    
                                    Основные места применения данного оборудования представлены ниже:
                                </p><br/>
                                <p className="equipment_block_card_description">    
                                    •	<b>в технических системах</b> для экспертизы состояния различных объектов;
                                </p>
                                <p className="equipment_block_card_description">   
                                    •   <b>в таможенном ведомстве</b> при решении задач досмотра и контроля пассажирских и грузовых перевозок;
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	<b>ликвидации последствий чрезвычайных происшествий</b> (осмотр завалов, труднодоступных мест);
                                </p>
                                <p className="equipment_block_card_description">
                                    •	<b>осмотра объектов коммунального хозяйства</b> (колодцы, лифтовое оборудование, коллекторы и т.п.);
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	<b>проведение специальных работ подразделениями служб спасения и безопасности.</b>
                                </p>
                                <p className="equipment_block_card_description">    
                                    •	<b>проведении досмотра и технического обслуживания транспортных средств;</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}